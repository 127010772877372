import { TableColumn } from "../../types/table-column";

export const newestCasesColumns: TableColumn[] = [
  {
    type: "string",
    fieldName: "PROJECTS",
    value: "title",
    secondaryValue: "internalId",
    width: "22%",
  },
  {
    type: "object",
    fieldName: "RISK_LEVEL",
    value: "category",
    width: "22%",
  },
  {
    type: "progress",
    fieldName: "PROGRESS",
    value: "progress",
    width: "18%",
  },
  {
    type: "date",
    fieldName: "LAST_CHANGE",
    value: "updated_at",
    width: "15%",
  },
];

export const myCasesColumns: TableColumn[] = [
  {
    type: "string",
    fieldName: "PROJECTS",
    value: "title",
    secondaryValue: "internalId",
    width: "35%",
  },
  {
    type: "progress",
    fieldName: "PROGRESS",
    value: "progress",
    width: "15%",
  },

  {
    type: "members",
    fieldName: "MEMBERS",
    value: "members",
    secondaryValue: "totalMembers",
    width: "15%",
  },
  {
    type: "date",
    fieldName: "LAST_CHANGE",
    value: "updated_at",
    width: "15%",
  },
];

export const AITeamColumns: TableColumn[] = [
  {
    type: "string",
    fieldName: "TEMAS",
    value: "title",
    translatedValue: true,
    secondaryValue: "sub_title",
    width: "35%",
  },
  {
    type: "string",
    fieldName: "TOTAL",
    value: "total",
    width: "10%",
  },

  {
    type: "date",
    fieldName: "LAST_ADDED",
    value: "last_added",
    width: "15%",
  },
  {
    type: "members",
    fieldName: "MEMBERS",
    value: "members",
    secondaryValue: "total",
    width: "15%",
  },
];
