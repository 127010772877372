import axios from "axios";
import { config } from "../../config";

export class TasksService {
  public list = async (params: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `tasks`, { params });
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public counts = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `tasks/counts`);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listByCase = async (caseId: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + `tasks/case/${caseId}`);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public add = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + `tasks`, data);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };

  public update = async (id: number, data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(serverUrl + `tasks/${id}`, data);
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public delete = async (id: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(serverUrl + `tasks/${id}`);
      return response.data;
    } catch (err: any) {
      return err.errors[0].message;
    }
  };

  public addTaskComment = async (id: string, payload: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "tasks/" + id + "/add-comment",
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public deleteTaskComment = async (id: number): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.delete(
        serverUrl + `tasks/delete-comment/${id}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateTaskComment = async (id: string, payload: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.put(
        serverUrl + "tasks/edit-comment/" + id,
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
}
