import React from "react";
import "./task-comment.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import TaskCommentsListComponent from "../task-comments-list/task-comments-list.component";
import { Comment } from "../../../../../types/comment";
import TaskAddComment from "../task-add-comment/task-add-comment.component";
import { icons } from "../../../../../statics/icons";
import { useTranslation } from "react-i18next";

interface TaskCommentProps {
  status: number;
  comments: any[];
  user?: any;
  taskId: number;
  internalId: string;
  onDeleteComment: (index: number) => void;
  onUpdateComments: (todo: any) => void;
}

const TaskComment = (props: TaskCommentProps) => {
  const [t] = useTranslation();
  const {
    comments,
    user,
    taskId,
    status,
    internalId,
    onDeleteComment,
    onUpdateComments,
  } = props;
  return (
    <Box className="comments-wrapper position-relative px-4 py-3">
      <Typography
        variant="subtitle1"
        className="d-flex align-items-center mb-4"
        style={{
          cursor: "pointer",
          fontWeight: 400,
          gap: ".3rem",
        }}
      >
        <img src={icons.COMMENTS} alt="Task Comments" />
        {`${comments.length} ${t("COMMENTS")}`}
      </Typography>

      {status === 0 && user.role !== "auditor" ? (
        <TaskAddComment
          user={user}
          internalId={internalId}
          taskId={taskId}
          updateComments={(newComment: string) => onUpdateComments(newComment)}
        />
      ) : null}

      <Box className="d-flex flex-column w-100 mt-4">
        {comments.map((comment: Comment, index: any) => {
          return (
            <TaskCommentsListComponent
              comment={comment.content}
              commentIndex={index}
              isLastComment={comments.length - 1 === index}
              user={comment.user}
              date={comment.createdAt}
              files={comment.files}
              id={comment.id}
              key={index}
              isAuthorizedUser={
                (user.keycloakUserId === comment.userId && status === 0) ||
                user.type === "superAdmin" ||
                user.role === "coordinator"
              }
              taskId={taskId}
              deleteComment={(deletedIndex: number) =>
                onDeleteComment(deletedIndex)
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TaskComment;
