import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../../store";
import "./profile-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../../statics/icons";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import { isAllTextSpaces, showToastMsg } from "../../../../../helpers";
import { UserService } from "../../../../../services/user/user.service";
import { UserProfileForm } from "../../../../../types/user-profile";
import DefaultInputView from "../../../../../components/default-input-view/default-input-view.component";
import {
  digitOnly,
  emailRegex,
  nameRegex,
  telephoneRegex,
} from "../../../../../statics/validators";
import { User } from "../../../../../types/user";
import { LocalStorageService } from "../../../../../services/local-storage/local-storage";
import ChangePassword from "../../../../../dialogs/change-password/change-password";
import { SettingsService } from "../../../../../services/settings/settings.service";
import { DefaultCategories } from "../../../../../statics/default-categories";
import { userTitlesValue } from "../../../../../statics/user-titles";
import OnboardingCategory from "../../../../../components/onboarding-category/onboarding-category.component";
import SettingSectionTitleComponent from "../../../shared/setting-section-title/setting-section-title.component";
import ProfilePictureComponent from "../profile-picture/profile-picture.component";
import SupportBlockComponent from "../../../../../components/support/support.component";

export default function ProfileContent(props: any) {
  const [t] = useTranslation();
  const userService: UserService = new UserService();
  const settingsService: SettingsService = new SettingsService();
  const localStorageService: LocalStorageService = new LocalStorageService();
  const emptyArr: any = [];

  const { user, fullNav, setUser, helpData, setHelpContent } = useContext(
    GlobalContext
  );

  const [profileForm, setProfileForm] = useState(new UserProfileForm());
  const [fromChanged, setFormChanged] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [companyRegisterNumberError, setCompanyRegisterNumberError] = useState(
    false
  );
  const [telephoneError, setTelephoneError] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [userCategories, setUserCategories] = useState(emptyArr);
  const [workspaceCategories, setWorkspaceCategories] = useState(emptyArr);

  const submit = () => {
    const payload: any = new UserProfileForm();
    const profileFormCopy: any = { ...profileForm };
    for (const key of Object.keys(payload)) {
      payload[key] = profileFormCopy[key] ? profileFormCopy[key] : null;
    }
    delete payload.profilePicURL;
    updateProfile(payload);
  };

  async function updateProfile(payload: any) {
    const res = await userService.updateUserInfo(payload);
    if (res && res.msg === "Updated") {
      setUser({ ...profileForm });
      localStorageService.setData("user", profileForm);
      showToastMsg("success", t("User data updated successfully"));
      setFormChanged(false);
    }
  }

  const getUserCategories = async () => {
    const userCategories = await settingsService.getUserCategories();
    const categories = await settingsService.listCategories();
    setUserCategories(userCategories);
    setWorkspaceCategories(categories);
  };

  useEffect(() => {
    if (user.role && user.role === "editor") {
      getUserCategories();
    }
    setHelpContent(helpData.UserManagement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, helpData]);

  useEffect(() => {
    setProfileForm({ ...new User(), ...user });
  }, [user]);

  const handleFormChange = (event: any) => {
    let value = event.target.value;
    const name = event.target.name;
    setFormChanged(true);

    if (isAllTextSpaces(value)) {
      return;
    }
    if (name === "telephone") {
      value
        ? setTelephoneError(!telephoneRegex.test(value))
        : setTelephoneError(false);
    } else if (name === "companyEmail") {
      value ? setEmailError(!emailRegex.test(value)) : setEmailError(false);
    } else if (name === "firstName") {
      value = value.trim();
      value
        ? setFirstNameError(!nameRegex.test(value))
        : setFirstNameError(false);
    } else if (name === "lastName") {
      value = value.trim();
      value
        ? setLastNameError(!nameRegex.test(value))
        : setLastNameError(false);
    } else if (name === "companyPostalCode") {
      value = value.trim();
      value
        ? setPostalCodeError(!digitOnly.test(value))
        : setPostalCodeError(false);
    } else if (name === "companyRegisterNumber") {
      value
        ? setCompanyRegisterNumberError(!telephoneRegex.test(value))
        : setCompanyRegisterNumberError(false);
    }

    const obj: any = {};
    obj[name] = value;
    setProfileForm({ ...profileForm, ...obj });
  };

  const onUploadImage = async (
    profilePicKey: string,
    profilePicURL: string
  ) => {
    const data = { ...profileForm, profilePicKey, profilePicURL };
    const res = await userService.updateUserInfo({ profilePicKey });
    if (res && res.msg === "Updated") {
      setUser({ ...data });
      setProfileForm({ ...data });
      localStorageService.setData("user", data);
      showToastMsg("success", t("PROFILE_PICTURE_UPDATED_SUCCESSFULLY"));
      setFormChanged(false);
    }
  };

  const closePasswordModal = (value: any) => {
    setShowPasswordModal(false);
    if (value) {
      showToastMsg("success", t("USER_PASSWORD_UPDATED_SUCCESSFULLY"));
    }
  };

  const onDeleteImg = async () => {
    const res = await userService.updateUserInfo({ profilePicKey: null });
    if (res && res.msg === "Updated") {
      const data = { ...profileForm, profilePicKey: "", profilePicURL: "" };
      setUser({ ...data });
      setProfileForm({ ...data });
      showToastMsg("success", t("PROFILE_PICTURE_DELETED_SUCCESSFULLY"));
    }
  };

  return (
    <>
      <section className="profile-content">
        <div className="personal-info">
          <SettingSectionTitleComponent
            title={t("USER_DATA")}
            subTitle={t("CHANGE_YOUR_PERSONAL_ACCOUNT_INFORMATION_HERE")}
          />

          <Box className="row">
            <Box className="col-5">
              <ProfilePictureComponent
                onUploadSuccess={onUploadImage}
                onDelete={onDeleteImg}
              />
            </Box>
          </Box>

          <div className="row">
            <div className="col-9 mb-40px">
              <div className="profile-card">
                <div className="card-content">
                  <div className="mt-3 mb-4">
                    <div className="row no-gutters flex-wrap justify-content-between">
                      <div className="w-30 mb-2">
                        <FormControl className="select-input select-grey w-100">
                          <InputLabel id="title">{t("TITLE")}</InputLabel>
                          <Select
                            labelId="title"
                            id="title-select"
                            name="title"
                            value={profileForm.title || ""}
                            onChange={handleFormChange}
                            IconComponent={ExpandMoreIcon}
                          >
                            {userTitlesValue.map((item: any, index: any) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="w-66 mb-2">
                        <TextField
                          className="w-100"
                          label={t("firstName")}
                          variant="filled"
                          name="firstName"
                          value={profileForm.firstName || ""}
                          error={firstNameError ? true : false}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </div>
                      <div className="w-48 mb-2">
                        <TextField
                          className="w-100"
                          label={t("lastName")}
                          variant="filled"
                          name="lastName"
                          value={profileForm.lastName || ""}
                          error={lastNameError ? true : false}
                          onChange={handleFormChange}
                          inputProps={{
                            maxLength: 30,
                          }}
                        />
                      </div>
                      <div className="w-48 mb-2">
                        <TextField
                          className="w-100"
                          label={t("telephone number")}
                          variant="filled"
                          name="telephone"
                          value={profileForm.telephone || ""}
                          error={telephoneError ? true : false}
                          onChange={handleFormChange}
                        />
                        {/* <div
                          className="d-flex align-items-center error-msg mt-1"
                          style={{ visibility: telephoneError ? "visible" : "hidden" }}>
                          <img src={icons.alert} alt="" className="alert-icon mr-2" width={10} />
                          <div className="trim">
                            {t("invalid telephone number")}
                          </div>
                        </div> */}
                      </div>
                      <div className="w-100 mb-3">
                        <DefaultInputView
                          label="Email"
                          value={user.email || ""}
                        />
                      </div>
                      <div className={fullNav ? "w-100 mb-3" : "w-48"}>
                        <DefaultInputView
                          label={t("password")}
                          value="password-hint"
                        />
                      </div>
                      <div
                        className={"btn-container " + (fullNav ? "w-100" : "")}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn-secondary-theme w-100 btn-password"
                          onClick={setShowPasswordModal.bind(null, true)}
                        >
                          <img src={icons.passwordPrimary} alt="" />{" "}
                          {t("change password")}
                        </Button>
                      </div>
                      <ChangePassword
                        show={showPasswordModal}
                        onClose={closePasswordModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user.group === "external" && (
              <div className="col-9 mb-40px">
                <div className="company-card">
                  <div className="card-content">
                    <div className="mt-3 mb-4">
                      <div className="row no-gutters flex-wrap justify-content-between">
                        <div className="w-100 mb-3">
                          <TextField
                            className="w-100"
                            label={t("company name type of company")}
                            variant="filled"
                            name="companyName"
                            value={profileForm.companyName}
                            onChange={handleFormChange}
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        </div>
                        <div className="w-100 mb-3">
                          <TextField
                            className="w-100"
                            label={t("street house number")}
                            variant="filled"
                            name="companyStreet"
                            value={profileForm.companyStreet}
                            onChange={handleFormChange}
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        </div>
                        <div className="w-28 mb-3">
                          <TextField
                            className="w-100 overflow-hidden"
                            label={t("commercial register number")}
                            variant="filled"
                            name="companyRegisterNumber"
                            value={profileForm.companyRegisterNumber}
                            onChange={handleFormChange}
                            inputProps={{
                              maxLength: 15,
                            }}
                          />
                        </div>
                        <div className="w-20 mb-3">
                          <TextField
                            className="w-100"
                            label={t("postcode")}
                            variant="filled"
                            name="companyPostalCode"
                            value={profileForm.companyPostalCode}
                            onChange={handleFormChange}
                            error={postalCodeError ? true : false}
                            inputProps={{
                              maxLength: 10,
                            }}
                          />
                        </div>
                        <div className="w-44 mb-3">
                          <TextField
                            className="w-100"
                            label={t("city")}
                            variant="filled"
                            name="companyCity"
                            value={profileForm.companyCity}
                            onChange={handleFormChange}
                            inputProps={{
                              maxLength: 25,
                            }}
                          />
                        </div>
                        <div className="w-100">
                          <TextField
                            className="w-100"
                            label="Email Office"
                            variant="filled"
                            name="companyEmail"
                            type="email"
                            value={profileForm.companyEmail}
                            error={emailError ? true : false}
                            onChange={handleFormChange}
                            inputProps={{
                              maxLength: 50,
                            }}
                          />
                        </div>
                        {emailError && (
                          <div className="error-msg pt-1">
                            {t("invalid-email")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-9">
              <div className="footer d-flex flex-row justify-content-end align-items-end mt-4">
                <Button
                  className="btn-primary-theme btn-save"
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={
                    telephoneError ||
                    emailError ||
                    firstNameError ||
                    lastNameError ||
                    postalCodeError ||
                    companyRegisterNumberError ||
                    !fromChanged
                  }
                >
                  <CheckIcon /> {t("save changes")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {user.role === "editor" && userCategories.length !== 0 && (
          <div className="user-category">
            <SettingSectionTitleComponent
              title={t("main processor responsibilities")}
              subTitle={t("main processor description")}
            />
            <div className="categories">
              {DefaultCategories.map((category: any, index: number) => {
                return workspaceCategories.map((cat: any, i: any) => {
                  return (
                    <>
                      {cat.key === category.key && (
                        <OnboardingCategory
                          key={index}
                          name={cat.name}
                          src={category.src}
                          activeSrc={category.activeSrc}
                          borderNum={1}
                          active={userCategories.some(
                            (item: any) => item.key === cat.key
                          )}
                        />
                      )}
                    </>
                  );
                });
              })}
            </div>
          </div>
        )}

        {user.role !== "coordinator" && user.type !== "superAdmin" && (
          <div className="contacts-section">
            <SettingSectionTitleComponent title={t("Contact us")} subTitle="" />
            {/* <ContactInfo /> */}
            <SupportBlockComponent />
          </div>
        )}
      </section>
    </>
  );
}
