import React, { useState } from "react";
import "./task-add-comment.component.scoped.scss";
import { Box } from "@material-ui/core";
import TaskAddCommentFormComponent from "../task-add-comment-form/task-add-comment-form.component";
import FilesDropZone from "../../../../../components/files-drop-zone/files-drop-zone.component";
import { imageTypes } from "../../../../../statics/file-types";

const TaskAddComment = (props: any) => {
  const { updateComments, taskId, internalId } = props;
  const [files, setFiles] = useState([]);

  return (
    <Box className="comment-wrapper mx-1">
      <FilesDropZone onDropFiles={setFiles} acceptTypes={imageTypes}>
        <TaskAddCommentFormComponent
          taskId={taskId}
          droppedFiles={files}
          internalId={internalId}
          onAddedComment={(res: any) => updateComments(res.comment)}
        />
      </FilesDropZone>
    </Box>
  );
};

export default TaskAddComment;
