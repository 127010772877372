import React, { useState } from "react";
import "./timeline-delete-comment.component.scoped.scss";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import { useTranslation } from "react-i18next";
import { TasksService } from "../../services/tasks/tasks.service";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";
import { dummyComments } from "../../statics/dummy-comments";
import { dummyFiles } from "../../statics/dummy-files";
import { dummyCommunication } from "../../statics/dummy-notes";

export const TimelineDeleteCommentComponent = (props: any) => {
  const [t] = useTranslation();
  const {
    caseDetails,
    setCaseComments,
    setCaseNotes,
    setCommunicationsLoading,
    setNotesLoading,
    setFilesLoading,
    setCaseFiles,
    setSelectedFile,
  } = React.useContext(CaseContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const casesService = new CasesService();
  const tasksService = new TasksService();

  const handleClose = (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      handleDelete();
    }
  };

  const handleDelete = async () => {
    if (props.tab === "reporter") {
      await casesService.deleteReporterComment(props.id);
      fetchCaseComments(caseDetails.id);
    } else if (props.tab === "workflow") {
      const res = await casesService.deleteWorkflowComment(props.id);
      if (res && res.message === "Success") {
        props.deleteComment(props.index);
      }
    } else if (props.tab === "files") {
      const res = await casesService.deleteFile(caseDetails.id, props.fileId);
      if (res.message === "Success") {
        fetchCaseFiles(caseDetails.id);
      }
    } else if (props.tab === "communication") {
      await casesService.deleteCommunicationComment(props.id);
      fetchCaseNotes(caseDetails.id);
    } else if (props.tab === "tasks") {
      const res = await tasksService.deleteTaskComment(props.id);
      if (res && res.message === "Success") {
        props.deleteComment(props.index);
      }
    }
  };

  async function fetchCaseComments(id: number) {
    setCommunicationsLoading(true);
    setCaseComments(dummyComments);
    const caseComments = await casesService.caseComments(id);
    setTimeout(() => {
      setCaseComments(caseComments);
      setCommunicationsLoading(false);
    }, 100);
  }
  async function fetchCaseNotes(id: number) {
    setNotesLoading(true);
    setCaseNotes(dummyCommunication);
    const caseNotes = await casesService.getCaseCommunication(id);
    setTimeout(() => {
      setCaseNotes(caseNotes);
      setNotesLoading(false);
    }, 100);
  }
  async function fetchCaseFiles(id: number) {
    setFilesLoading(true);
    setCaseFiles(dummyFiles);
    const caseFiles = await casesService.caseFiles(id);
    if (caseFiles.length) setSelectedFile(caseFiles[0]);
    else setSelectedFile(null);
    setTimeout(() => {
      setCaseFiles(caseFiles);
      setFilesLoading(false);
    }, 100);
  }

  return (
    <div className="delete-comment" onClick={() => setShowConfirmModal(true)}>
      <DeleteOutlineIcon />
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={handleClose}
          dialogTitle={t("confirm delete")}
          dialogBody={
            props.tab === "files"
              ? t("are you sure you want to delete the file")
              : t("are you sure you want to delete the comment")
          }
          confirmBtnText={t("delete")}
          cancelBtn={true}
        />
      )}
    </div>
  );
};
