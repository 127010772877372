import React, { useContext, useEffect, useState } from "react";
import "./tasks-wrapper.component.scoped.scss";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  styled,
  Typography,
} from "@material-ui/core";
import Switch, { SwitchProps } from "@material-ui/core/Switch";

import { useTranslation } from "react-i18next";
import { MapperService } from "../../../../services/mapper/mapper.service";
import { TasksContext } from "../../../../store/providers/tasks.provider";
import { defaultItems } from "../../../../statics/pagination";
import { TasksFooterComponent } from "../tasks-footer/tasks-footer.component";
import { TasksService } from "../../../../services/tasks/tasks.service";
import TasksCard from "../tasks-cards/tasks-cards.component";
import { TasksCountCards } from "../../../../statics/countCards";
import { Skeleton } from "@material-ui/lab";
import TasksRowComponent from "../tasks-row/tasks-row.component";
import { GlobalContext } from "../../../../store";

const SwitchUI = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: "0 5px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--accent-color)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      backgroundColor: "#E9E9EA",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function TasksWrapperComponent() {
  const [t] = useTranslation();
  const tasksService = new TasksService();
  const mapperService = new MapperService();

  const emptyObj = {};
  const emptyArr: any = [];

  const { todoList, setTodoList, pagination, setPagination } = useContext(
    TasksContext
  );
  const { user } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(emptyObj);
  const [pending, setPending] = useState(false);
  const [complete, setComplete] = useState(false);
  const [cards, setCards] = useState<any>([]);

  const getQuery = () => {
    return new URLSearchParams(window.location.search);
  };

  const fetchTodos = async (filter?: any) => {
    setLoading(true);
    setTodoList(emptyArr);
    const response = await tasksService.list(filter);
    const { readyTodos, paginationObj } = mapperService.mapTodoList(response);
    setPagination(paginationObj);
    setTodoList(readyTodos);
    setLoading(false);
  };

  const fetchCounts = async () => {
    const response = await tasksService.counts();
    const mappedCounts = mapperService.mapCounts(response, TasksCountCards);
    setCards(mappedCounts);
  };

  const onFilter = (e: any) => {
    e.persist();
    const caseId = getQuery().get("caseId");
    const filterObj: any = {
      items: pagination.items,
      page: 1,
      caseId,
    };

    if (e.target.checked) {
      if (e.target.name === "pending") {
        setPending(e.target.checked);
        filterObj.status = 0;
        setParams(filterObj);
      } else if (e.target.name === "complete") {
        setComplete(e.target.checked);
        filterObj.status = 1;
        setParams(filterObj);
      }
    } else {
      setPending(false);
      setComplete(false);
      setParams(filterObj);
    }
    fetchTodos(filterObj);
  };

  const paginate = (pagination: any) => {
    const { items, currentPage } = pagination;
    const filterObj = {
      ...params,
      items,
      page: currentPage,
    };
    setParams(filterObj);
    fetchTodos(filterObj);
  };

  useEffect(() => {
    const caseId = getQuery().get("caseId");
    const paginationParams = { items: defaultItems, page: 1, caseId };
    fetchCounts();
    setParams(paginationParams);
    fetchTodos(paginationParams);
    // eslint-disable-next-line
  }, []);

  const refresh = () => {
    fetchTodos(params);
  };

  return (
    <Box className="todo-wrapper">
      <Box className="tasks__cards">
        {cards.map((card: any, index: number) => {
          return (
            <TasksCard
              title={t(card.title)}
              key={index}
              icon={card.icon}
              total={card.total}
            />
          );
        })}
      </Box>
      <Box className="my-4 d-flex align-items-center justify-content-between">
        {loading ? (
          <Box className="d-flex align-items-center" style={{ gap: ".4rem" }}>
            <Skeleton variant="text" height={30} width={30} />
            <Skeleton variant="text" height={30} width={100} />
          </Box>
        ) : (
          <Typography variant="h5">
            {pagination.total} {t("TASKS")}
          </Typography>
        )}
        {loading ? (
          <Box className="d-flex align-items-center" style={{ gap: "1rem" }}>
            <Box className="d-flex align-items-center" style={{ gap: ".4rem" }}>
              <Skeleton variant="text" height={30} width={30} />
              <Skeleton variant="text" height={30} width={100} />
            </Box>
            <Box className="d-flex align-items-center" style={{ gap: ".4rem" }}>
              <Skeleton variant="text" height={30} width={30} />
              <Skeleton variant="text" height={30} width={100} />
            </Box>
          </Box>
        ) : (
          <Box className="filter_action">
            <FormControlLabel
              className="switch-global m-0"
              label={t("OPEN_TASKS")}
              labelPlacement="start"
              control={
                <SwitchUI
                  onChange={onFilter}
                  name="pending"
                  size="medium"
                  disabled={complete}
                  checked={pending}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              className="switch-global m-0"
              labelPlacement="start"
              label={t("COMPLETED_TASK")}
              control={
                <SwitchUI
                  onChange={onFilter}
                  name="complete"
                  size="medium"
                  color="primary"
                  disabled={pending}
                  checked={complete}
                />
              }
            />
          </Box>
        )}
      </Box>
      <Box className="todo-contain">
        {loading && todoList && !todoList.length && (
          <Box className="loader-area">
            <CircularProgress />
          </Box>
        )}
        {todoList && todoList.length === 0 && !loading && (
          <Box className="no-result">{t("NO_DATA_AVAILABLE")}</Box>
        )}
        {todoList.map((row: any, index: number) => {
          return (
            <TasksRowComponent
              refresh={refresh}
              key={index}
              data={row}
              user={user}
            />
          );
        })}
        {!loading && !!todoList.length && (
          <TasksFooterComponent paginate={paginate} pagination={pagination} />
        )}
      </Box>
    </Box>
  );
}
