import React, { useState, useEffect } from "react";
import "./dashboard-first-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardCard from "../dashboard-card/dashboard-card.component";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";

export default function DashboardFirstBlock(props: any) {
  const [t] = useTranslation();
  const [cards, setCards] = useState(props.counts);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedCards = reorder(
      cards,
      result.source.index,
      result.destination.index
    );
    setCards(orderedCards);
  };

  useEffect(() => {
    setCards(props.counts);
  }, [props.counts]);

  return (
    <Box className="content mb-5">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list" direction="horizontal">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="cards__content"
            >
              {cards.map((card: any, index: number) => {
                return (
                  <Draggable key={card.id} draggableId={card.id} index={index}>
                    {(
                      provided: DraggableProvided,
                      snapshot: DraggableStateSnapshot
                    ) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={"card__content"}
                      >
                        <DashboardCard
                          title={t(card.title)}
                          total={card.total}
                          icon={card.icon}
                          isDraggable={true}
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box className="global__box__content p-0">
        <Box className="box__content">
          <Box className="info">
            <Typography variant="h6">
              Connect Today & Join the pAIper.One AI Community
            </Typography>
            <Typography variant="subtitle2">
              Join today and gain access to exclusive courses, insightful
              videos, and personalized 1:1 coaching sessions with legal experts
              and AI Act professionals. Stay ahead of the curve and ensure
              compliance with the latest regulations.
            </Typography>
          </Box>
          <img
            src={icons.PAGES.DASHBOARD.JOIN_COMMUNITY}
            alt=""
            className="m-auto"
          />
        </Box>
        <Box className="action">
          <a
            href={"https://ai-community.paiper.one"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("DASHBOARD.GET_START")}
          </a>
        </Box>
      </Box>
    </Box>
  );
}
