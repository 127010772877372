import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import "./workflow-table-row.component.scoped.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import SendIcon from "@material-ui/icons/Send";
import { CasesService } from "../../../../../../../services/cases/cases.service";
import { CaseContext } from "../../../../../../../store";
import {
  formatDate,
  isValidLength,
  showToastMsg,
} from "../../../../../../../helpers";
import { icons } from "../../../../../../../statics/icons";
import WorkflowComment from "../workflow-comments/workflow-comment/workflow-comment.component";
import WorkflowTodo from "../workflow-todolist/workflow-todo/workflow-todo.component";
import { ConfirmDialog } from "../../../../../../../dialogs/confirm-dialog/confirm-dialog";
import { QuestionBriefDialog } from "../../../../../dialogs/question-brief/question-brief.component";
import {
  AnswerOptions,
  AnswerOptionsList,
} from "../../../../../types/answerOptions";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const WorkflowTableRow = (props: any) => {
  const { user, onUpdateCaseWorkflow } = props;
  const [t] = useTranslation();
  const wrapperRef = useRef<HTMLInputElement>(null);
  const casesService: CasesService = new CasesService();

  const {
    workflowLoading,
    caseDetails,
    setCase,
    setWorkflowLoading,
  } = useContext(CaseContext);

  const [saving, setSaving] = useState(false);
  const [answer, setAnswer] = useState(props.answer);

  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState(props.comments);

  const [openTodoList, setOpenTodoList] = useState(false);
  const [todoList, setTodoList] = useState(props.todoList);

  const [question, setQuestion] = useState(props.question);
  const [updatedQuestion, setUpdatedQuestion] = useState(props.question);
  const [editQuestion, setEditQuestion] = useState(false);

  const [expandInfo, setExpandInfo] = useState(false);

  const [
    showConfirmDeleteQuestionModal,
    setShowConfirmDeleteQuestionModal,
  ] = useState(false);
  const [showQuestionBriefDialog, setShowQuestionBriefDialog] = useState(false);
  const [isQuestionHover, setIsQuestionHover] = useState(false);

  const fetchCaseDetails = async (id: number) => {
    const caseDetailsData = await casesService.details(id);
    const mappedCase = { ...caseDetails, ...caseDetailsData };
    setCase(mappedCase);
  };

  const deleteQuestion = async (value: any) => {
    if (saving) return;
    setShowConfirmDeleteQuestionModal(false);
    if (value) {
      setSaving(true);
      const response = await casesService.deleteQuestion(
        props.id,
        props.questionId
      );
      setSaving(false);
      if (response.message === "Success") {
        showToastMsg("success", t("QUESTION_DELETED_SUCCESSFULLY"));
        props.onDeleteQuestion();
      } else {
        showToastMsg("error", t("SOME_THING_WENT_WRONG"));
      }
    }
  };

  const updateQuestion = async () => {
    setSaving(true);
    const res = await casesService.editQuestion(props.questionId, {
      question: updatedQuestion,
    });
    setSaving(false);
    if (res.message === "Success") {
      setEditQuestion(false);
      setQuestion(updatedQuestion);
      showToastMsg("success", t("QUESTION_UPDATED_SUCCESSFULLY"));
    } else {
      showToastMsg("error", t("SOME_THING_WENT_WRONG"));
    }
  };

  const handleAnswerChange = async (value: number) => {
    const answerCheck = value;
    if (answer === null) {
      setShowQuestionBriefDialog(true);
      setAnswer(answerCheck);
    } else {
      setWorkflowLoading(true);
      setAnswer(null);
      await casesService.answerQuestion(props.id, { answer: null, brief: "" });
      onUpdateCaseWorkflow();
    }
  };

  const updateAnswer = async (value: any) => {
    setShowQuestionBriefDialog(false);
    if (value) {
      onUpdateCaseWorkflow();
    } else {
      if (!props.brief) {
        setAnswer(null);
      }
    }
  };

  const handleUpdateComments = (comment: string) => {
    const commentsCopy = [...comments];
    commentsCopy.push(comment);
    setComments(commentsCopy);
  };

  const handleDeleteComment = (index: number) => {
    const commentsCopy = [...comments];
    commentsCopy.splice(index, 1);
    // empty the comments first for rerender again.
    setComments([]);
    setComments(commentsCopy);
  };

  const handleUpdateTodoList = (todo: any) => {
    fetchCaseDetails(caseDetails.id);
    const todosCopy = [...todoList];
    todo.question = question;
    todo.questionId = props.questionId;
    todosCopy.push(todo);
    setTodoList(todosCopy);
  };

  const handleDeleteTodoList = (index: number) => {
    fetchCaseDetails(caseDetails.id);
    const todosCopy = [...todoList];
    todosCopy.splice(index, 1);
    // empty the comments first for rerender again.
    setTodoList([]);
    setTodoList(todosCopy);
  };

  const refresh = () => {
    onUpdateCaseWorkflow();
  };

  useEffect(() => {
    // hide input if clicked on outside of input
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setEditQuestion(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleFocus = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  };

  useEffect(() => {
    setTodoList(props.todoList);
  }, [props.todoList]);

  return (
    <Box
      className="question-wrapper mb-4"
      onMouseOver={() => setIsQuestionHover(true)}
      onMouseLeave={() => setIsQuestionHover(false)}
    >
      {editQuestion && props.answer === null ? (
        <div className="col px-3 py-2" ref={wrapperRef}>
          <TextField
            id="timeline-add-comment"
            placeholder={t("ADD_NEW_QUESTION_TO_CURRENT_STATUS")}
            variant="filled"
            fullWidth
            label={t("QUESTION")}
            onChange={(e: any) => setUpdatedQuestion(e.target.value)}
            value={updatedQuestion}
            name="question"
            inputRef={handleFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SendIcon
                    className={
                      isValidLength(updatedQuestion, 100)
                        ? "svg-active"
                        : "svg-grey"
                    }
                    onClick={updateQuestion}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : (
        <Box
          className="content"
          // style={answer !== null ? { opacity: 0.6 } : {}}
        >
          <Box className="timeline-badge">
            {props.loading ? (
              <Skeleton variant="circle" height={30} width={30} />
            ) : (
              <Box
                className={props.answer !== null ? "bg-green" : "bg-primary"}
              >
                <img
                  src={
                    props.answer !== null
                      ? icons.checkWhite
                      : icons.noteAddLight
                  }
                  alt=""
                />
              </Box>
            )}
          </Box>
          {/* Start question section */}
          <Box className="task-section">
            <Box className="d-flex justify-content-between align-items-center">
              {props.loading ? (
                <Skeleton variant="text" height={35} width={500} />
              ) : (
                <>
                  <Box className="d-flex justify-content-start align-items-center">
                    <Typography
                      variant="body1"
                      style={{ fontSize: 16, fontWeight: 400 }}
                      color="secondary"
                    >
                      {question}
                    </Typography>
                    {isQuestionHover &&
                      props.custom &&
                      (user.type === "superAdmin" ||
                        user.role === "coordinator") && (
                        <Box className="ml-3 action d-flex align-items-center">
                          {props.answer === null && (
                            <Box
                              className="icon-wrap mr-1"
                              onClick={() => setEditQuestion(true)}
                            >
                              <img src={icons.edit} alt="" />
                            </Box>
                          )}
                          <Box
                            className="icon-wrap"
                            onClick={() =>
                              setShowConfirmDeleteQuestionModal(true)
                            }
                          >
                            <DeleteOutlineIcon />
                          </Box>
                        </Box>
                      )}
                    {/* <ExpandLessIcon /> */}
                  </Box>
                  <Box
                    onClick={setExpandInfo.bind(null, !expandInfo)}
                    className="expand-icon"
                  >
                    {expandInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {/* End question section */}
          {/* Start answers options section */}
          <Box
            className={`answer-wrapper ${
              answer !== null && !props.loading ? "answered" : "my-3"
            }`}
          >
            <Box className="actions">
              {AnswerOptionsList.map((item: AnswerOptions) => {
                return (
                  <React.Fragment key={item.id}>
                    {props.loading ? (
                      <Box className="d-flex align-items-center mb-2">
                        <Skeleton
                          variant="text"
                          height={48}
                          width={150}
                          style={{ marginRight: "15px" }}
                        />
                      </Box>
                    ) : (
                      <Button
                        className={`btn-secondary-theme mb-0`}
                        variant={
                          answer === item.answer ? "contained" : "outlined"
                        }
                        color="primary"
                        size="medium"
                        onClick={() => handleAnswerChange(item.answer)}
                        disabled={
                          user.role === "auditor" ||
                          (answer !== null && answer !== item.answer)
                        }
                      >
                        {t(item.label)}
                      </Button>
                    )}
                  </React.Fragment>
                );
              })}
            </Box>
            <Box className="answer">
              {props.brief && !props.loading && (
                <Box
                  className="d-flex align-items-start"
                  style={{ gap: "24px" }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      wordBreak: "break-word",
                    }}
                    onClick={() => setShowQuestionBriefDialog(true)}
                  >
                    {props.brief}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {/* End answers options section */}

          {/* Start info section */}
          <Box className="info-section my-3">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              {props.loading ? (
                <Box className="d-flex align-items-center">
                  <Skeleton
                    variant="rect"
                    height={20}
                    width={20}
                    style={{ marginRight: "5px" }}
                  />
                  <Skeleton
                    variant="text"
                    height={22}
                    width={150}
                    style={{ marginRight: "15px" }}
                  />
                </Box>
              ) : (
                <Box className={`icon-wrap`}>
                  <CalendarTodayIcon />
                  <Box>
                    {t("LAST_UPDATE_DATE")}:{" "}
                    {formatDate(props.updatedAt, "DD/MM/YYYY")}
                  </Box>
                </Box>
              )}
              {!expandInfo && (
                <>
                  {props.loading ? (
                    <Box className="d-flex align-items-center">
                      <Skeleton
                        variant="rect"
                        height={20}
                        width={20}
                        style={{ marginRight: "5px" }}
                      />
                      <Skeleton
                        variant="text"
                        height={22}
                        width={100}
                        style={{ marginRight: "15px" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      className={`icon-wrap underline`}
                      onClick={() => {
                        setExpandInfo(!expandInfo);
                        setOpenComments(!openComments);
                        setOpenTodoList(false);
                      }}
                    >
                      <QuestionAnswerOutlinedIcon />
                      <Box>
                        {comments.length} {t("COMMENTS")}
                      </Box>
                    </Box>
                  )}

                  {props.loading ? (
                    <Box className="d-flex align-items-center">
                      <Skeleton
                        variant="rect"
                        height={20}
                        width={20}
                        style={{ marginRight: "5px" }}
                      />
                      <Skeleton
                        variant="text"
                        height={22}
                        width={100}
                        style={{ marginRight: "15px" }}
                      />
                    </Box>
                  ) : (
                    <Box
                      className={`icon-wrap underline`}
                      onClick={() => {
                        setExpandInfo(!expandInfo);
                        setOpenComments(false);
                        setOpenTodoList(!openTodoList);
                      }}
                    >
                      <img src={icons.todoLightGrey} alt="" />
                      <Box>
                        {todoList.length} {t("TASKS")}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Box className={`toggle  ${expandInfo ? "show" : ""}`}>
              {expandInfo && (
                <Box className="collapse-section">
                  <Box id="info__comment">
                    {props.loading ? (
                      <Box className="d-flex align-items-center">
                        <Skeleton
                          variant="rect"
                          height={20}
                          width={20}
                          style={{ marginRight: "5px" }}
                        />
                        <Skeleton
                          variant="text"
                          height={22}
                          width={100}
                          style={{ marginRight: "15px" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        className={`icon-wrap`}
                        onClick={() => {
                          setOpenComments(!openComments);
                          setOpenTodoList(false);
                        }}
                      >
                        <Box
                          className={`expand-icon ${
                            comments.length === 0 &&
                            props.answer !== null &&
                            "disabled"
                          }`}
                        >
                          {openComments &&
                          (comments.length || props.answer === null) ? (
                            <ArrowDownIcon />
                          ) : (
                            <ArrowRightIcon />
                          )}
                        </Box>

                        <QuestionAnswerOutlinedIcon />
                        <Box>
                          {comments.length} {t("COMMENTS")}
                        </Box>
                      </Box>
                    )}

                    {/* Start Comment Section */}
                    <Box
                      className={`
                      toggle  ${
                        openComments &&
                        (comments.length || props.answer === null)
                          ? "show"
                          : ""
                      }`}
                    >
                      {openComments &&
                      (comments.length || props.answer === null) ? (
                        <WorkflowComment
                          answer={props.answer}
                          comments={comments}
                          workflowLoading={workflowLoading}
                          user={user}
                          caseQuestionId={props.id}
                          onDeleteComment={(index: number) =>
                            handleDeleteComment(index)
                          }
                          onUpdateComments={(comment: any) =>
                            handleUpdateComments(comment)
                          }
                        />
                      ) : null}
                    </Box>
                    {/* End Comment Section */}
                  </Box>

                  {user.role !== "auditor" && (
                    <Box id="info__todo">
                      {props.loading ? (
                        <Box className="d-flex align-items-center">
                          <Skeleton
                            variant="rect"
                            height={20}
                            width={20}
                            style={{ marginRight: "5px" }}
                          />
                          <Skeleton
                            variant="text"
                            height={22}
                            width={100}
                            style={{ marginRight: "15px" }}
                          />
                        </Box>
                      ) : (
                        <Box
                          className={`icon-wrap`}
                          onClick={() => {
                            setOpenTodoList(!openTodoList);
                            setOpenComments(false);
                          }}
                        >
                          <Box
                            className={`expand-icon ${
                              todoList.length === 0 &&
                              props.answer !== null &&
                              "disabled"
                            }`}
                          >
                            {openTodoList &&
                            (todoList.length || props.answer === null) ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowRightIcon />
                            )}
                          </Box>

                          <img src={icons.todoLightGrey} alt="" />
                          <Box>
                            {todoList.length} {t("TASKS")}
                          </Box>
                        </Box>
                      )}
                      {/* Start TodoList Section */}
                      <Box
                        className={`
                      toggle  ${
                        openTodoList &&
                        (todoList.length || props.answer === null)
                          ? "show"
                          : ""
                      }`}
                      >
                        {openTodoList &&
                        (todoList.length || props.answer === null) ? (
                          <WorkflowTodo
                            user={user}
                            question={question}
                            answer={props.answer}
                            todoList={todoList}
                            workflowLoading={workflowLoading}
                            caseQuestionId={props.id}
                            refresh={refresh}
                            onDeleteTodoList={(index: number) =>
                              handleDeleteTodoList(index)
                            }
                            onUpdateTodoList={(todo: any) =>
                              handleUpdateTodoList(todo)
                            }
                          />
                        ) : null}
                      </Box>
                      {/* End TodoList Section */}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {/* End info section */}
        </Box>
      )}

      {showConfirmDeleteQuestionModal && (
        <ConfirmDialog
          show={showConfirmDeleteQuestionModal}
          onClose={deleteQuestion}
          dialogTitle={t("DELETE_QUESTION")}
          dialogBody={t("DELETE_QUESTION_DESCRIPTION")}
          confirmBtnText={t("confirm")}
          cancelBtn={true}
        />
      )}

      {showQuestionBriefDialog && (
        <QuestionBriefDialog
          show={showQuestionBriefDialog}
          id={props.id}
          brief={props.brief}
          answer={answer}
          onClose={updateAnswer}
        />
      )}
    </Box>
  );
};

export default WorkflowTableRow;

// {/* Files Section */}
// {openFiles && (files.length || props.answer === null) ? (
//   <>
//     <Box className="files-wrapper p-3">
//       <Typography
//         variant="body1"
//         className="mr-4"
//         style={{
//           color: "#4a4a4a",
//           fontSize: "15px",
//           fontWeight: 500,
//         }}
//       >
//         {t("FILES")}
//       </Typography>
//       <Box className="d-flex flex-column w-100">
//         {props.answer === null ? (
//           <WorkflowAddFiles
//             caseFiles={props.caseFiles}
//             questionId={props.id}
//             updateQuestionFile={(file: any) => handleUpdateFiles(file)}
//           />
//         ) : null}
//       </Box>
//     </Box>

//     <Box className="row p-3">
//       {files.map((file: any, index: number) => {
//         return (
//           <Box className="col-3" key={index}>
//             <WorkflowFile
//               name={file.name}
//               type={file.type}
//               size={file.size}
//               url={file.url}
//               id={file.id}
//               isAuthorizedUser={
//                 user.keycloakUserId === caseDetails.mainProcessorId ||
//                 user.keycloakUserId === file.userId ||
//                 user.type === "superAdmin" ||
//                 user.role === "coordinator"
//               }
//               canDeleteFile={
//                 props.answer === null &&
//                 caseDetails.statusId !== completedStatusId
//               }
//               deleteFile={() => handleDeleteFile(index)}
//             />
//           </Box>
//         );
//       })}
//     </Box>
//   </>
// ) : null}
