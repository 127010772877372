import React, { Fragment, useContext, useEffect } from "react";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import DashboardContent from "../../modules/dashboard/components/dashboard-content/dashboard-content.component";
import { GlobalContext } from "../../store";

export default function DashboardPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.DASHBOARD"));
      setPageTitle(t("PAGE_META_TITLES.DASHBOARD"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <HeaderComponent />
      <DashboardContent />
    </Fragment>
  );
}
