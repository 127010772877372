import { icons } from "./icons";

export const DashboardCountCards = [
  {
    id: "1",
    key: "totalMembers",
    title: "COUNTS_CARDS.TOTAL_MEMBERS",
    icon: icons.PAGES.DASHBOARD.MEMBERS,
  },
  {
    id: "2",
    key: "totalCourses",
    title: "COUNTS_CARDS.TOTAL_COURSES",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "3",
    key: "totalExperts",
    title: "COUNTS_CARDS.TOTAL_EXPERTS",
    icon: icons.PAGES.DASHBOARD.EXPERTS,
  },
  {
    id: "4",
    key: "totalVideos",
    title: "COUNTS_CARDS.TOTAL_VIDEOS",
    icon: icons.PAGES.DASHBOARD.VIDEOS,
  },
];

export const LandScapeCountCards = [
  {
    id: "1",
    key: "totalProjects",
    title: "COUNTS_CARDS.TOTAL_PROJECTS",
    icon: icons.PAGES.DASHBOARD.PROJECTS,
  },
  {
    id: "2",
    key: "totalTasks",
    title: "COUNTS_CARDS.TOTAL_TASKS",
    icon: icons.PAGES.DASHBOARD.TASKS,
  },
  {
    id: "3",
    key: "highRisk",
    title: "COUNTS_CARDS.HIGHT_RISK_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "4",
    key: "limitRisk",
    title: "COUNTS_CARDS.LIMITED_MINIMAL_RISK_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "5",
    key: "lowRisk",
    title: "COUNTS_CARDS.GP_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "6",
    key: "openTasks",
    title: "COUNTS_CARDS.OPEN_TASKS",
    icon: icons.PAGES.DASHBOARD.TASKS,
  },
];

export const TasksCountCards = [
  {
    id: "1",
    key: "allTasks",
    title: "COUNTS_CARDS.TOTAL_TASKS",
    icon: icons.PAGES.DASHBOARD.PROJECTS,
  },
  {
    id: "3",
    key: "pending",
    title: "COUNTS_CARDS.OPEN_TASKS",
    icon: icons.PAGES.DASHBOARD.TASKS,
  },
  {
    id: "2",
    key: "completed",
    title: "COUNTS_CARDS.COMPLETE_TASKS",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "4",
    key: "overdue",
    title: "COUNTS_CARDS.OVERDUE_TASKS",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
];
