import React, { useContext, useEffect } from "react";
import "./tasks.page.scoped.scss";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import { TasksProvider } from "../../store/providers/tasks.provider";
import { GlobalContext } from "../../store";
import TasksContent from "../../modules/tasks/components/tasks-content/tasks-content.component";

export default function TasksPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.AI_TASKS"));
      setPageTitle(t("PAGE_META_TITLES.AI_TASKS"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TasksProvider>
      <HeaderComponent />
      <TasksContent />
    </TasksProvider>
  );
}
