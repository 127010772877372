import React, { useContext, useEffect, useRef } from "react";
import "./task-add-comment-form.component.scoped.scss";
import { Box, makeStyles } from "@material-ui/core";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import {
  TextField,
  InputAdornment,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GlobalContext } from "../../../../../store";
import { CasesService } from "../../../../../services/cases/cases.service";
import { isValidLength, turncate } from "../../../../../helpers";
import { FileSizes } from "../../../../../statics/file-sizes";
import { alertToastConfig } from "../../../../../statics/alert-config";
import { fileTypes } from "../../../../../statics/file-types";
import { icons } from "../../../../../statics/icons";
import { TasksService } from "../../../../../services/tasks/tasks.service";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "var(--accent-color)",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const TextFieldStyle = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: "#fff !important", // Change this to your desired color
    },
  },
}));

const TaskAddCommentFormComponent = (props: any) => {
  const { onAddedComment, taskId, droppedFiles, internalId } = props;
  const [t] = useTranslation();
  const textFieldStyle = TextFieldStyle();

  const { user } = useContext(GlobalContext);

  const maxChar = 500;
  const maxFiles = 5;
  const emptyArr: any = [];
  const tasksServices = new TasksService();
  const casesService = new CasesService();
  const [comment, setComment] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [filesListProgress, setFilesListProgress] = React.useState(emptyArr);
  const [filesList, setFilesList] = React.useState(emptyArr);
  const [filesListCanceled, setFilesListCanceled] = React.useState(emptyArr);
  const inputFileRef = useRef(null);
  const uploadEl = useRef(null);

  const submit = async () => {
    if (
      !saving &&
      isValidLength(comment, maxChar) &&
      filesList.length <= maxFiles &&
      comment
    ) {
      setSaving(true);
      const filesSavedOrCanceled: any[] = [];
      const filesProgress: any[] = [];
      const filesCanceled: any[] = [];
      const filesUploaded: any[] = [];
      if (filesList.length) {
        filesList.forEach((file: any, index: number) => {
          casesService
            .requestSignedUrl(
              file.name,
              getFileInfo(file.type).fileType,
              "files",
              internalId
            )
            .then((response: any) => {
              const { signedRequest, url } = response;
              const cancelTokenSource = axios.CancelToken.source();
              filesList[index].cancelTokenSource = cancelTokenSource;
              filesList[index].url = url;
              casesService
                .uploadToSignedURL(
                  signedRequest,
                  filesList[index],
                  cancelTokenSource.token,
                  (event: any) => {
                    filesProgress[index] = Math.round(
                      (100 * event.loaded) / event.total
                    );
                    setFilesListProgress([
                      ...filesListProgress,
                      ...filesProgress,
                    ]);
                  }
                )
                .then((uploadResponse: any) => {
                  if (uploadResponse === "canceled") {
                    filesSavedOrCanceled.push(file);
                    filesCanceled.push(file);
                    setFilesListCanceled([...filesCanceled]);
                    if (
                      filesUploaded.length + filesCanceled.length ===
                      filesList.length
                    ) {
                      saveCommentWithFiles(filesUploaded);
                    }
                    return;
                  }
                  const type = getFileInfo(file.type).fileType;
                  const name = file.name.split("." + type)[0];
                  const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                  const payload = {
                    name,
                    type,
                    size,
                    url: file.url,
                  };
                  filesUploaded.push(payload);
                  if (
                    filesUploaded.length + filesCanceled.length ===
                    filesList.length
                  ) {
                    saveCommentWithFiles(filesUploaded);
                  }
                });
            });
        });
      } else {
        saveCommentWithFiles();
      }
    }
  };

  const saveCommentWithFiles = async (files: any[] = []) => {
    const result = await tasksServices.addTaskComment(taskId, {
      comment,
      files,
    });
    onAddedComment(result);
    resetData();
  };

  const resetData = () => {
    setSaving(false);
    setComment("");
    setFilesList([]);
    setFilesListProgress([]);
  };

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const validateFiles = async (files: any[]) => {
    const filteredFilesList: any = [];
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];

      if (file.size > maxSize) {
        errorsMsg.push(
          `<Box class='alert-file-name'>${turncate(
            fileName,
            20
          )}</Box> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        const newFile: any = new File(
          [file],
          file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, "-"),
          { type: file.type }
        );
        if (fileType.includes("jpg") || fileType.includes("png")) {
          newFile.src = await getImagePreview(newFile);
          filteredFilesList.push(newFile);
        } else {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    setFilesList([...filesList, ...filteredFilesList]);
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  const getImagePreview = async (file: File) => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  };

  const showAlertMsg = async (queues: any[]) => {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  };

  const deleteFile = (index: number) => {
    const filesCopy = [...filesList];
    if (filesList[index].cancelTokenSource) {
      filesList[index].cancelTokenSource.cancel();
      filesList[index].canceled = true;
    } else {
      filesCopy.splice(index, 1);
      setFilesList(filesCopy);
    }
  };

  const clickUpload = () => {
    if (!saving) {
      const element: any = inputFileRef.current;
      element.click();
    }
  };

  const handleUploadFocus = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.add("active");
  };

  const handleUploadBlur = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.remove("active");
  };

  useEffect(() => {
    if (!saving && droppedFiles && droppedFiles.length) {
      validateFiles(droppedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFiles]);

  return (
    <Box className="w-100">
      <input
        type="file"
        ref={inputFileRef}
        onChange={onFileChange}
        style={{ display: "none" }}
        multiple
        accept={fileTypes}
        disabled={saving || user.role === "auditor"}
      />
      <TextField
        onFocus={handleUploadFocus}
        onBlur={handleUploadBlur}
        className="text-area-multiline upload-area w-100"
        id="timeline-file-upload"
        label={t("add file and description to communicate with clerk")}
        multiline
        rows={3}
        variant="filled"
        value={comment}
        onChange={(e: any) => setComment(e.target.value)}
        disabled={saving || user.role === "auditor"}
        classes={{
          root: textFieldStyle.root,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AttachFileIcon
                className="upload-icon mr-3"
                onClick={clickUpload}
              />
              <SendIcon
                className={
                  !saving &&
                  comment &&
                  isValidLength(comment, maxChar) &&
                  filesList.length <= maxFiles
                    ? "svg-active"
                    : "svg-grey"
                }
                onClick={submit}
              />
            </InputAdornment>
          ),
        }}
      />
      <div className="files-preview" ref={uploadEl}>
        {filesList.map((file: any, index: any) => {
          return (
            !file.canceled && (
              <Box
                className={"file-preview " + (saving ? "loading" : "")}
                key={index}
              >
                <StyledTooltip title={file.name} placement="bottom">
                  <img
                    src={
                      file.src
                        ? file.src
                        : icons[getFileInfo(file.type).fileType]
                    }
                    alt=""
                  />
                </StyledTooltip>
                {filesListProgress[index] !== 100 && (
                  <Box
                    className="file-delete"
                    onClick={() => deleteFile(index)}
                  >
                    <ClearIcon />
                  </Box>
                )}
                {filesListProgress[index] === 100 && (
                  <Box className="file-success">
                    <DoneIcon />
                  </Box>
                )}
                {saving && filesListProgress[index] < 100 && (
                  <Box className="file-progress">
                    {filesListProgress[index] > 5 ? (
                      <CircularProgress
                        variant="static"
                        value={filesListProgress[index]}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                )}
              </Box>
            )
          );
        })}
      </div>

      <Box className="d-flex justify-content-end">
        <Box
          className={
            "input-char-count mt-1 mr-1 " +
            (filesList.length > maxFiles ? "invalid" : "")
          }
        >
          {filesList.length - filesListCanceled.length}/{maxFiles} {t("files")}
        </Box>
        <Box className="input-char-count mt-1 mr-3">
          (PDF max. 20MB; JPG, PNG max. 8MB; MP4 max. 200MB)
        </Box>
        <Box
          className={
            "input-char-count mt-1 " +
            (comment && !isValidLength(comment, maxChar) ? "invalid" : "")
          }
        >
          {comment.length}/{maxChar} {t("character")}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskAddCommentFormComponent;
