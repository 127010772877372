import React, { createContext, useReducer } from "react";
import TasksReducer from "../reducers/tasks.reducer";

const initialState: any = {
  todoList: [],
  pagination: {
    currentPage: 1,
    items: 15,
    totalPages: 1,
    total: 0,
  },
  counts: {
    total: 0,
    pending: 0,
    completed: 0,
    overdue: 0,
  },
};

export const TasksContext = createContext(initialState);

export const TasksProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(TasksReducer, initialState);

  function setTodoList(todoList: any[]) {
    dispatch({
      type: "SET_TODO_LIST",
      payload: { todoList },
    });
  }

  function setCounts(counts: any) {
    dispatch({
      type: "SET_COUNTS",
      payload: { counts },
    });
  }

  function setPagination(params: any) {
    dispatch({
      type: "SET_PAGINATION",
      payload: { params },
    });
  }

  const { todoList, pagination, counts } = state;

  return (
    <TasksContext.Provider
      value={{
        todoList,
        pagination,
        counts,
        setCounts,
        setTodoList,
        setPagination,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
