import React from "react";
import "./dashboard-second-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardGraphCard from "../dashboard-graph-card/dashboard-graph-card.component";
import { Box } from "@material-ui/core";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";

export default function DashboardSecondBlock(props: any) {
  const [t] = useTranslation();
  const { statusChart, cases } = props;
  return (
    <Box className="cards__content mb-5">
      {statusChart && (
        <Box className="card__content">
          <DashboardGraphCard
            title={t("DASHBOARD." + statusChart.title)}
            subTitle={t(statusChart.subTitle)}
            chart={statusChart.chart}
            type={statusChart.type}
          />
        </Box>
      )}
      {cases.data && (
        <Box className="card__content">
          <DashboardTableCard
            title={t(cases.title)}
            subTitle={t(cases.subTitle)}
            columns={cases.columns}
            tableName={cases.tableName}
            data={cases.data}
            clickableRow={true}
          />
        </Box>
      )}
    </Box>
  );
}
