import React from "react";
import "../auth.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";
import { history } from "react-router-guard";

const PasswordChangedComponent = () => {
  const [t] = useTranslation();

  return (
    <Box className="form-container text-center">
      <img
        src={icons.PAGES.AUTH.PASSWORD_CHANGED}
        alt="Password Changed"
        className="mb-4"
      />
      <Box className="mb-3 text-center">
        <Typography variant="h3">{t("YOUR_PASSWORD_IS_CHANGED")}</Typography>
        <Typography variant="body1" className="mt-2">
          {t("PASSWORD_CHANGED_DESC")}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className="mt-4"
        onClick={() => history.push("/login")}
      >
        {t("SIGN_IN")}
      </Button>
    </Box>
  );
};

export default PasswordChangedComponent;
