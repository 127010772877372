// import { SideNavItem } from "../types/sidenav-item";

import { icons } from "./icons";

export const sideMenuItems: any[] = [
  {
    name: "",
    components: [
      {
        path: "/dashboard",
        pageTitle: "dashboard",
        name: "SIDE_MENU.DASHBOARD",
        icon: icons.PAGES.SIDE_NAVE.DASHBOARD_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.DASHBOARD,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
    ],
  },
  {
    name: "SIDE_MENU.AI_GOVERNANCE_PLATFORM",
    components: [
      {
        path: "/landscape",
        pageTitle: "landscape",
        name: "SIDE_MENU.AI_RISK_LANDSCAPE",
        icon: icons.PAGES.SIDE_NAVE.LANDSCAPE_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.LANDSCAPE,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        path: "/ai-products",
        pageTitle: "AI-Products",
        name: "SIDE_MENU.AI_SOFTWARE_DEVELOPMENT",
        icon: icons.PAGES.SIDE_NAVE.AI_SOFTWARE_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_SOFTWARE,
        type: "component",
        roles: ["superAdmin", "coordinator", "editor", "auditor"],
      },
      {
        path: "/ai-inventory",
        pageTitle: "AI-Inventory",
        name: "SIDE_MENU.AI_INVENTORY",
        icon: icons.PAGES.SIDE_NAVE.AI_INVENTORY_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_INVENTORY,
        type: "component",
        roles: ["superAdmin", "coordinator", "editor", "auditor"],
      },
      {
        path: "/ai-demand",
        pageTitle: "AI-Demand",
        name: "SIDE_MENU.AI_DEMAND_MANAGEMENT",
        icon: icons.PAGES.SIDE_NAVE.AI_DEMAND_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_DEMAND,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        path: "/ai-compliance-tracking",
        pageTitle: "AI-Compliance-Tracking",
        name: "SIDE_MENU.AI_COMPLIANCE_TRACKER",
        icon: icons.PAGES.SIDE_NAVE.AI_TRAKING_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_TRAKING,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        path: "/ai-risk-assessment",
        pageTitle: "AI-Risk-Assessment",
        name: "SIDE_MENU.AI_RISK_ASSESSMENT",
        icon: icons.PAGES.SIDE_NAVE.AI_ASSESSMENT_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_ASSESSMENT,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        name: "SIDE_MENU.SETTINGS",
        icon: icons.PAGES.SIDE_NAVE.SETTINGS_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.SETTINGS,
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
        children: [
          {
            path: "/settings/workspace-management",
            name: "SIDE_MENU.SETTINGS_LIST.WORKSPACE",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
          {
            path: "/settings/profile",
            name: "SIDE_MENU.SETTINGS_LIST.PROFILE",
            type: "component",
            roles: [
              "superAdmin",
              "coordinator",
              "editor",
              "auditor",
              "contributor",
            ],
          },
          {
            path: "/settings/roles-management",
            name: "SIDE_MENU.SETTINGS_LIST.ROLE_MANAGEMENT",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
          {
            path: "/settings/workflow-management",
            name: "SIDE_MENU.SETTINGS_LIST.WORKFLOW_MANAGEMENT",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
          {
            path: "/settings/database-value-management",
            name: "SIDE_MENU.SETTINGS_LIST.DATABASE_VALUE_MANAGEMENT",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
          {
            path: "/settings/branch-management",
            name: "SIDE_MENU.SETTINGS_LIST.BRANCH_MANAGEMENT",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
          {
            path: "/settings/activity-log",
            name: "SIDE_MENU.SETTINGS_LIST.ACTIVITY_LOG",
            type: "component",
            roles: ["superAdmin", "coordinator"],
          },
        ],
      },
      {
        path: "/users",
        pageTitle: "SIDE_MENU.USER_MANAGEMENT",
        name: "SIDE_MENU.USER_MANAGEMENT",
        icon: icons.PAGES.SIDE_NAVE.USER_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.USER,
        type: "component",
        roles: ["superAdmin", "coordinator"],
      },
    ],
  },
  {
    name: "SIDE_MENU.TASK_MANAGEMENT",
    components: [
      {
        path: "/ai-tasks",
        pageTitle: "AI-Tasks",
        name: "SIDE_MENU.AI_TASKS",
        icon: icons.PAGES.SIDE_NAVE.AI_TASKS_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.AI_TASKS,
        type: "component",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
    ],
  },
  {
    name: "SIDE_MENU.PAGRS",
    components: [
      {
        path: "https://paiper.one/ueber-uns/",
        pageTitle: "company",
        name: "SIDE_MENU.COMPANY",
        icon: icons.PAGES.SIDE_NAVE.COMPANY_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.COMPANY,
        type: "link",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        path: "https://paiper.one/ai-news/",
        pageTitle: "blog",
        name: "SIDE_MENU.BLOG",
        icon: icons.PAGES.SIDE_NAVE.BLOG_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.BLOG,
        type: "link",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
    ],
  },
  {
    name: "SIDE_MENU.APPS",
    components: [
      {
        path: "https://ai-community.paiper.one",
        pageTitle: "AI-Community",
        name: "SIDE_MENU.AI_COMMUNITY",
        icon: icons.PAGES.SIDE_NAVE.COMMUNITY_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.COMMUNITY,
        type: "link",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
      {
        path: "https://paiper.one/ai-risk-assesment/",
        pageTitle: "AI-assistant",
        name: "SIDE_MENU.AI_ASSISTANT",
        icon: icons.PAGES.SIDE_NAVE.ASSISTENT_LIGHT,
        activeIcon: icons.PAGES.SIDE_NAVE.ASSISTENT,
        type: "link",
        roles: [
          "superAdmin",
          "coordinator",
          "editor",
          "auditor",
          "contributor",
        ],
      },
    ],
  },
];

// export const sideNavItems: SideNavItem[] = [
//   {
//     path: "/landscape",
//     title: "landscape",
//     name: "SIDE_MENU.AI_RISK_LANDSCAPE",
//     icon: icons.PAGES.SIDE_NAVE.DashboardLight,
//     iconHover: icons.PAGES.SIDE_NAVE.Dashboard,
//     roles: ["superAdmin", "editor", "coordinator", "auditor", "contributor"],
//   },
//   {
//     path: "/ai-products",
//     title: "AI-Products",
//     name: "SIDE_MENU.AI_PRODUCTS",
//     icon: icons.PAGES.SIDE_NAVE.AIProductsLight,
//     iconHover: icons.PAGES.SIDE_NAVE.AIProducts,
//     roles: ["superAdmin", "editor", "coordinator", "auditor"],
//   },
//   {
//     path: "/ai-inventory",
//     title: "AI-Inventory",
//     name: "SIDE_MENU.AI_INVENTORY",
//     icon: icons.PAGES.SIDE_NAVE.AIInventoryLight,
//     iconHover: icons.PAGES.SIDE_NAVE.AIInventory,
//     roles: ["superAdmin", "editor", "coordinator", "auditor"],
//   },
//   {
//     path: "/ai-demand",
//     title: "AI-Demand",
//     name: "SIDE_MENU.AI_DEMAND_MANAGER",
//     icon: icons.PAGES.SIDE_NAVE.AIDemandLight,
//     iconHover: icons.PAGES.SIDE_NAVE.AIDemand,
//     roles: ["superAdmin", "editor", "coordinator", "auditor", "contributor"],
//   },
//   {
//     path: "/ai-compliance-tracking",
//     title: "AI-Compliance-Tracking",
//     name: "SIDE_MENU.AI_COMPLIANCE_TRACKING",
//     icon: icons.PAGES.SIDE_NAVE.SupportLight,
//     iconHover: icons.PAGES.SIDE_NAVE.Support,
//     roles: ["superAdmin", "editor", "coordinator", "auditor"],
//   },
//   {
//     path: "/ai-risk-assessment",
//     title: "AI-Risk-Assessment",
//     name: "SIDE_MENU.AI_RISK_ASSESSMENT",
//     icon: icons.PAGES.SIDE_NAVE.WarningLight,
//     iconHover: icons.PAGES.SIDE_NAVE.Warning,
//     roles: ["superAdmin", "editor", "coordinator", "auditor"],
//   },
//   {
//     path: "/ai-tasks",
//     title: "AI-Tasks",
//     name: "SIDE_MENU.AI_TASK",
//     icon: icons.PAGES.SIDE_NAVE.TodoLight,
//     iconHover: icons.PAGES.SIDE_NAVE.Todo,
//     roles: ["superAdmin", "editor", "coordinator", "contributor"],
//   },
//   // {
//   //   name: "calender",
//   //   title: "calender",
//   //   path: "/calender",
//   //   icon: icons.calenderLight,
//   //   iconHover: icons.calender,
//   //   permissions: ["superAdmin", "user", "superUser", "auditor"],
//   // },
// ];
