import React from "react";
import "./landscape-third-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import LandscapeTableCard from "../landscape-table-card/landscape-table-card.component";
import LandscapeGraphCard from "../landscape-graph-card/landscape-graph-card.component";

export default function LandscapeThirdBlock(props: any) {
  const { statusChart, latestCases } = props;
  const [t] = useTranslation();

  return (
    <Box className="cards__content mb-5">
      {latestCases.data && (
        <Box className="card__content">
          <LandscapeTableCard
            title={t(latestCases.title)}
            subTitle={t(latestCases.subTitle)}
            columns={latestCases.columns}
            tableName={latestCases.tableName}
            data={latestCases.data}
          />
        </Box>
      )}
      {statusChart && (
        <Box className="card__content">
          <LandscapeGraphCard
            title={t("DASHBOARD." + statusChart.title)}
            subTitle={t(statusChart.subTitle)}
            chart={statusChart.chart}
            type={statusChart.type}
          />
        </Box>
      )}
    </Box>
  );
}
