import React, { useEffect, useState } from "react";
import "./sign-up-content.component.scoped.scss";
import "../auth.component.scoped.scss";
import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { CommonService } from "../../../../services/common/common.service";
import { history } from "react-router-guard";
import { UserService } from "../../../../services/user/user.service";
import {
  capitalChar,
  digitChar,
  specialChar,
  passwordRegex,
} from "../../../../statics/validators";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorAuthComponent from "../error-token/error-token.component";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const commonService: CommonService = new CommonService();
const userService: UserService = new UserService();

export const SignUpContentComponent = ({ token, tenantId }: any) => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [tokenValidityLoading, setTokenValidityLoading] = useState(false);
  const [capsLock, setCapsLock] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const inputPassword = (event: any) => {
    const value = event.target.value;
    setNewPassword(value);
    if (!passwordRegex.test(value)) {
      setNewPasswordError("Die Mindestlänge des Passworts beträgt 8 Zeichen");
    } else {
      setNewPasswordError("");
    }

    if (value && confirmation && value !== confirmation) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const inputConfirmation = (event: any) => {
    const value = event.target.value;
    setConfirmation(value);
    if (value && newPassword && value !== newPassword) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
  };

  const setPassword = async () => {
    try {
      setLoading(true);
      await userService.verifyNewUser(
        { keycloakUserId: token, password: newPassword.toString() },
        tenantId
      );
      history.push("/login");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const canUserSetPassword = async (token: any) => {
    setTokenValidityLoading(true);
    const response = await userService.checkTokenValidity(
      {
        token: token,
      },
      tenantId
    );
    if (response && response.validity === "valid") {
      setTokenIsValid(true);
    } else if (response && response.validity === "invalid") {
      setTokenIsValid(false);
    } else if (
      response &&
      response.validity === "user already has a password"
    ) {
      await userService.verifyNewUser({ keycloakUserId: token }, tenantId);
      history.push("/login");
    } else {
      setTokenIsValid(false);
    }
    setTokenValidityLoading(false);
  };

  const handleKeyDown = (e: any) => {
    if (
      e.key === "Enter" &&
      !passwordMatchError &&
      !newPasswordError &&
      newPassword.length >= 8 &&
      confirmation.length >= 8 &&
      termsChecked
    ) {
      setPassword();
    }
  };

  useEffect(() => {
    canUserSetPassword(token);
    // eslint-disable-next-line
  }, []);

  if (tokenValidityLoading) {
    return <CircularProgress />;
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <>
      {!tokenValidityLoading && tokenIsValid ? (
        <div className="form-container">
          <Box className="mb-3 text-center">
            <Typography variant="h3">{t("SIGN_UP")}</Typography>
            <Typography variant="body1" className="mt-2">
              {t("ALREADY_HAVE_AN_ACCOUNT")} <a href="/login">{t("SIGN_IN")}</a>
            </Typography>
          </Box>

          <form
            className="login-form"
            noValidate
            autoComplete="off"
            onKeyDown={handleKeyDown}
          >
            <Box className="standard_text_input" display="grid">
              <Typography variant="subtitle2" style={{ color: "#000" }}>
                {t("PASSWORD")}
              </Typography>
              <TextField
                variant="standard"
                className="w-100"
                type={showNewPassword ? "text" : "password"}
                autoComplete="off"
                onInput={inputPassword}
                placeholder={t("ENTER_PASSWORD")}
                // error={newPasswordError}
                onKeyDown={onKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="toggle-password"
                        aria-label="toggle password visibility"
                        onClick={setShowNewPassword.bind(
                          null,
                          !showNewPassword
                        )}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="standard_text_input" display="grid">
              <Typography variant="subtitle2" style={{ color: "#000" }}>
                {t("CONFIRM_PASSWORD")}
              </Typography>
              <TextField
                variant="standard"
                className="w-100"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="off"
                onInput={inputConfirmation}
                placeholder={t("RE_ENTER_PASSWORD")}
                // error={confirmationError}
                onKeyDown={onKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="toggle-password"
                        aria-label="toggle password visibility"
                        onClick={setShowConfirmPassword.bind(
                          null,
                          !showConfirmPassword
                        )}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {capsLock && (
              <div className="error-msg capsLock">
                {t("caps lock is turned on")}
              </div>
            )}

            <div className="password-validation">
              <p
                style={{
                  color: newPassword
                    ? newPassword.length < 8 || newPassword.length > 15
                      ? "#CA310B"
                      : "#a6ba8d"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("length 8 to 15 characters")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? specialChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 special character")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? capitalChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 capital letter")}
              </p>
              <p
                style={{
                  color: newPassword
                    ? digitChar.test(newPassword)
                      ? "#a6ba8d"
                      : "#CA310B"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("at least 1 digit")}
              </p>
              <p
                style={{
                  color: confirmation
                    ? passwordMatchError
                      ? "#CA310B"
                      : "#a6ba8d"
                    : "",
                }}
              >
                <CheckCircleOutlineIcon />
                {t("passwords do not match")}
              </p>
            </div>
            <div className="terms">
              <Checkbox
                checked={termsChecked}
                onChange={(e) => setTermsChecked(!termsChecked)}
                name="termsConditions"
                size="small"
              />
              <p>
                <Trans i18nKey="TERMS_CONDITIONS_DESC">
                  I accept{" "}
                  <a
                    href="https://compliance2b.at/datenschutz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </Trans>
                *
              </p>
            </div>

            <Button
              variant="contained"
              className="btn-primary-theme"
              color="primary"
              onClick={setPassword}
              disabled={
                passwordMatchError ||
                loading ||
                newPasswordError.length > 0 ||
                !newPassword ||
                !confirmation ||
                !termsChecked
              }
            >
              {!loading && t("SIGN_UP")}
              {loading && <CircularProgress color="primary" />}
            </Button>
          </form>
        </div>
      ) : (
        <ErrorAuthComponent />
      )}
    </>
  );
};
