import "./data-table.component.scoped.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  Box,
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { TableProps } from "../../../types/table-props";
import { TableContentComponent } from "../table-content/table-content.component";
import CasesFilterComponent from "../../cases-filter/cases-filter.component";
import {
  CasesFilterRiskLevels,
  CasesFilterStags,
  casesSearchColumns,
} from "../../../statics/casesTableFilter";
import SearchIcon from "@material-ui/icons/Search";
import { icons } from "../../../statics/icons";
import { history } from "react-router-guard";

export const DataTableComponent = (props: TableProps) => {
  const [t] = useTranslation();
  const {
    data,
    options,
    pagination,
    columns,
    sort,
    paginate,
    loading,
    reset,
    title,
    subTitle,
    clickableRow,
    tableName,
    reload,
    // exportData,
    onReload,
    // onExport,
    filter,
    onFilterChange,
    refresh,
    caseRole,
  } = props;

  const [internal, setInternal] = useState(
    filter?.group === "internal" ? true : false
  );
  const [external, setExternal] = useState(
    filter?.group === "external" ? true : false
  );
  const [auditor, setAuditor] = useState(
    filter?.role === "auditor" ? true : false
  );

  const tools = {
    sort: (...args: any) => sort(...args),
  };

  const onFilter = (e: any) => {
    e.persist();
    let attr = "";
    let value = "";
    if (e.target.name === "internal") {
      attr = "group";
      value = e.target.checked ? "internal" : "";
      setInternal(e.target.checked);
    } else if (e.target.name === "external") {
      attr = "group";
      value = e.target.checked ? "external" : "";
      setExternal(e.target.checked);
    } else if (e.target.name === "auditor") {
      attr = "role";
      value = e.target.checked ? "auditor" : "";
      setAuditor(e.target.checked);
    }
    onFilterChange(value, attr);
  };

  const onSearch = useCallback(
    debounce((query) => {
      onFilterChange({ searchText: query });
    }, 600),
    []
  );

  const createUseCase = () => {
    history.push(`/create-case?role=${caseRole}`);
  };

  useEffect(() => {
    setInternal(filter?.group === "internal" ? true : false);
    setExternal(filter?.group === "external" ? true : false);
    setAuditor(filter?.role === "auditor" ? true : false);
  }, [filter]);

  return (
    <Box className="table-wrapper">
      <Box className="table-head flex-wrap">
        <Box className="d-flex align-items-center flex-wrap">
          <Box className="table-title mr-3">{title}</Box>
          {subTitle && <Box className="table-total mt-1">{subTitle}</Box>}
        </Box>

        <Box className="table-extra">
          {tableName === "users" && (
            <Box className="table-filter d-flex mr-3">
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="auditor"
                    size="small"
                    checked={auditor}
                    disabled={external || internal}
                  />
                }
                label={t("auditor")}
              />
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="internal"
                    size="small"
                    checked={internal}
                    disabled={external || auditor}
                  />
                }
                label={t("intern")}
              />
              <FormControlLabel
                className="checkbox-global"
                control={
                  <Checkbox
                    onChange={onFilter}
                    name="external"
                    size="small"
                    checked={external}
                    disabled={internal || auditor}
                  />
                }
                label={t("extern")}
              />
            </Box>
          )}

          {reload && (
            <Button
              variant="outlined"
              color="primary"
              className="btn-secondary-theme text-normal"
              onClick={onReload}
            >
              <CachedIcon className="mr-2" /> {t("reload list")}
            </Button>
          )}

          {/* {exportData && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="btn-primary-theme"
              onClick={onExport}
            >
              <img src={icons.downloadWhite} alt="" className="mr-3" />
              Export
            </Button>
          )} */}
        </Box>

        {tableName === "cases" && (
          <Box className="w-100 mt-4">
            <Box className="standard_text_input" display="flex">
              <TextField
                variant="standard"
                type="text"
                autoComplete="off"
                onChange={(e: any) => onSearch(e.target.value)}
                placeholder={t("FIND_AI_PROJECT")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="cases_filters w-100 mt-4">
              <Box className="cases_filters_content">
                <Typography
                  variant="body2"
                  className="mb-2"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  {t("FILTER_BY_TOP_CATEGORIES")}
                </Typography>
                <Box className="cases_filters_chips">
                  <Box className="cases-filter-chip mr-4">
                    <Box
                      className={`chip ${filter?.all && "active"}`}
                      onClick={onFilterChange.bind(null, {
                        caseStage: null,
                        riskLevel: null,
                        all: filter?.all ? false : true,
                      })}
                    >
                      {t("ALL")}
                    </Box>
                  </Box>

                  <Box className="cases-filter-chip mr-4">
                    {CasesFilterStags.map((stag, index: number) => (
                      <Box
                        key={index}
                        className={`chip ${
                          filter?.caseStage === stag.key && "active"
                        }`}
                        onClick={onFilterChange.bind(null, {
                          caseStage:
                            filter?.caseStage === stag.key ? null : stag.key,
                          all: false,
                        })}
                      >
                        {t(stag.name)}
                      </Box>
                    ))}
                  </Box>

                  <Box className="cases-filter-chip">
                    {CasesFilterRiskLevels.map((risk: any, index: number) => (
                      <Box
                        className={`chip ${
                          filter?.riskLevel === risk.key && "active"
                        }`}
                        key={index}
                        onClick={onFilterChange.bind(null, {
                          riskLevel:
                            filter?.riskLevel === risk.key ? null : risk.key,
                          all: false,
                        })}
                      >
                        {t(risk.name)}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box
                className="cases_actions"
                display="flex"
                alignItems="center"
                gridGap=".5rem"
                justifyContent="end"
              >
                <CasesFilterComponent
                  onFiltersChange={onFilterChange}
                  searchableFields={casesSearchColumns}
                />
                <Button
                  className="btn-primary-theme"
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={createUseCase}
                >
                  <img
                    src={
                      caseRole === "PRODUCTS"
                        ? icons.PAGES.CASES.AIProductsWhite
                        : icons.PAGES.CASES.AIInventoryWhite
                    }
                    alt={title}
                    title={title}
                    className="mr-2"
                  />
                  {t("PAGES_HEAD.CREATE_NEW")}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box className="global__box__content table-container p-0">
        {loading && !data?.length && (
          <Box className="loader-area">
            <CircularProgress />
          </Box>
        )}
        <TableContentComponent
          options={options}
          data={data}
          columns={columns}
          tools={tools}
          loading={loading}
          reset={reset}
          paginate={paginate}
          pagination={pagination}
          clickableRow={clickableRow}
          refresh={refresh}
          tableName={tableName}
        />
      </Box>
    </Box>
  );
};
