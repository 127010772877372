import React, { useContext, useEffect, useState } from "react";
import "../auth.component.scoped.scss";
import "./third-party-login.componsnt.scoped.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../store";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { CommonService } from "../../../../services/common/common.service";
import { history } from "react-router-guard";
import { UserService } from "../../../../services/user/user.service";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { icons } from "../../../../statics/icons";

const userService: UserService = new UserService();
const commonService: CommonService = new CommonService();

export const ThirdPartyComponent = (props: any) => {
  console.log(props);
  const { clientId, callbackURI, state } = props.params;
  const [t] = useTranslation();

  commonService.setTitle(t("Case Management"));
  const { validate, errors, email, password, setLoginError } = useContext(
    AuthContext
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [capsLock, setCapsLock] = useState(false);
  const { email: emailError, password: passwordError } = errors;
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (event: any) => {
    setLoginError(null);
    const value = event.target.value;
    setError("");
    return validate("email", value);
  };

  const inputPassword = (event: any) => {
    setLoginError(null);
    const value = event.target.value;
    setError("");
    return validate("password", value);
  };

  const login = async () => {
    if (Object.keys(errors).length > 0 || loading || !email || !password) {
      return;
    }
    try {
      setLoading(true);
      const res = await userService.login({ email, password, clientId });
      if (res.token && res.verified) {
        window.location.href = `${callbackURI}?state=${state}&code=${res.code}`;
      } else {
        setError("invalid_user_credentials");
      }
    } catch (error) {
      return setLoginError("login-error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !Object.keys(errors).length) {
      login();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      setCapsLock(true);
    } else {
      setCapsLock(false);
    }
  };

  return (
    <Box className="third__party__content">
      <Box className="form-container">
        <Box className="head mb-3">
          <img src={icons.logo} alt="" width={45} />
          <Box className="title">
            <Typography variant="body1">
              {t("AI_EDUCATION_COMMUNITY_BY_PAIPER")}
            </Typography>
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              {t("SIGN_IN")}
            </Typography>
          </Box>
        </Box>

        <form
          className="form-content"
          noValidate
          autoComplete="off"
          onKeyDown={handleKeyDown}
        >
          <Box className="standard_text_input" display="grid">
            <Typography variant="subtitle2" style={{ color: "#000" }}>
              {t("EMAIL")}
            </Typography>
            <TextField
              variant="standard"
              className="w-100"
              type="text"
              autoComplete="off"
              onInput={validateEmail}
              placeholder={t("EMAIL")}
              error={emailError}
            />
          </Box>

          <Box className="standard_text_input" display="grid">
            <Typography variant="subtitle2" style={{ color: "#000" }}>
              {t("PASSWORD")}
            </Typography>
            <TextField
              variant="standard"
              className="w-100"
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              onInput={inputPassword}
              placeholder={t("PASSWORD")}
              error={passwordError}
              onKeyDown={onKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="toggle-password"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {capsLock && (
            <Box className="error-msg capsLock">
              {t("caps lock is turned on")}
            </Box>
          )}

          {error && <Box className="error-msg">{t(error)}</Box>}

          <Button
            variant="contained"
            className="btn-primary-theme"
            color="primary"
            onClick={login}
            disabled={
              Object.keys(errors).length > 0 || loading || !email || !password
            }
          >
            {!loading && t("LOGIN")}
            {loading && <CircularProgress color="primary" />}
          </Button>
        </form>

        <Typography variant="body1" className="text-center mt-4">
          {t("NEED_AN_ACCOUNT")}{" "}
          <a href="https://paiper.one/ai-risk-assesment/" target="_blank">
            {t("CONTACT_US")}
          </a>
        </Typography>
      </Box>
    </Box>
  );
};
