import React from "react";
import "./landscape-second-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import LandscapeTableCard from "../landscape-table-card/landscape-table-card.component";
import LandscapeGraphCard from "../landscape-graph-card/landscape-graph-card.component";

export default function LandscapedSecondBlock(props: any) {
  const [t] = useTranslation();
  const { categoriesChart, highRiskCases } = props;

  return (
    <Box className="cards__content mb-5">
      {categoriesChart && (
        <Box className="card__content">
          <LandscapeGraphCard
            title={t("DASHBOARD." + categoriesChart.title)}
            subTitle={t(categoriesChart.subTitle)}
            chart={categoriesChart.chart}
            type={categoriesChart.type}
          />
        </Box>
      )}
      {highRiskCases.data && (
        <Box className="card__content">
          <LandscapeTableCard
            title={t(highRiskCases.title)}
            subTitle={t(highRiskCases.subTitle)}
            columns={highRiskCases.columns}
            tableName={highRiskCases.tableName}
            data={highRiskCases.data}
          />
        </Box>
      )}
    </Box>
  );
}
