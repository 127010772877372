import { highRiskCasesColumns } from "./table-columns/high-risk-cases-columns";
import {
  AITeamColumns,
  myCasesColumns,
  newestCasesColumns,
} from "./table-columns/dashboard-table-columns";
import { openTasksColumns } from "./table-columns/open-tasks";

export const TableCards = [
  {
    id: "1",
    title: "NEWEST_PROJECTS",
    subTitle: "",
    tableName: "LATEST_CASES",
    columns: newestCasesColumns,
    data: [],
  },
  {
    id: "2",
    title: "HIGH_RISK_AI_PROJECTS",
    subTitle: "",
    tableName: "HIGH_RISK_CASES",
    columns: highRiskCasesColumns,
    data: [],
  },
  {
    id: "3",
    title: "MY_OPEN_AI_TASKS",
    subTitle: "",
    tableName: "OPEN_TASKS",
    columns: openTasksColumns,
    data: [],
  },
  {
    id: "4",
    title: "MY_PROJECTS",
    subTitle: "",
    tableName: "PROJECTS",
    columns: myCasesColumns,
    data: [],
  },
  {
    id: "5",
    title: "AI_TEAM",
    subTitle: "",
    tableName: "TEAM",
    columns: AITeamColumns,
    data: [],
  },
];
