import React from "react";
import { DashboardCardProps } from "../../../../types/dashboard-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./dashboard-card.component.scoped.scss";
import { Box } from "@material-ui/core";

export default function DashboardCard(props: DashboardCardProps) {
  const { title, total, icon, isDraggable, isDragging } = props;
  return (
    <Box
      className={
        "dashboard-card global__box__content global__box__content_bg " +
        (isDragging ? "dragging" : "")
      }
    >
      <Box
        className="draggable-icon"
        style={{ visibility: isDraggable ? "visible" : "hidden" }}
      >
        <ShuffleIcon />
      </Box>
      <Box className="d-grid align-items-center p-relative">
        <Box className="card-icon">
          <img src={icon} alt="" />
        </Box>
        <Box className="card-details">
          <Box className="card-total trim">{total}</Box>
          <Box className="card-title trim">{title}</Box>
        </Box>
      </Box>
    </Box>
  );
}
