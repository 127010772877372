import React, { useState, useContext, useEffect, useRef } from "react";
import "./header.component.scoped.scss";
import { icons } from "../../statics/icons";
import { history } from "react-router-guard";
import { GlobalContext } from "../../store";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import NotificationComponent from "../notification-component/notification-component.component";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MapperService } from "../../services/mapper/mapper.service";
import Drawer from "@material-ui/core/Drawer";
import useOutsideAlerter from "../../hooks/useOutisdeAlert";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { UserService } from "../../services/user/user.service";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default function HeaderComponent(props: any) {
  const [t] = useTranslation();
  const localStorageService: LocalStorageService = new LocalStorageService();
  const dashboardService: DashboardService = new DashboardService();
  const notificationsService: NotificationsService = new NotificationsService();
  const mapperService: MapperService = new MapperService();
  const userService: UserService = new UserService();
  const notificationsRef: any = useRef(null);
  const dropDownMenuRefs = [useRef(null), useRef(null)];

  let routeName = "/";
  if (history.location.pathname !== "/temp") {
    routeName = history.location.pathname;
  }

  const {
    setLang,
    lang,
    user,
    notifications,
    unseenNotifications,
    setUnseenNotifications,
    setNotifications,
    helpContent,
    setHelpData,
    setHelpContent,
    userTenants,
    setUserTenants,
    pageTitle,
  } = useContext(GlobalContext);

  const emptyArr: any = [];
  const [userImageError, setUserImageError] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const [page, setPage] = useState(1);

  useOutsideAlerter(notificationsRef, () => {
    setNotificationsOpen(false);
  });

  useOutsideAlerter(dropDownMenuRefs, () => {
    setOpenDropdownMenu(false);
  });

  const showSidebar = () => {
    setOpenDropdownMenu(false);
    setNotificationsOpen(false);
    setSidebar(!sidebar);
  };

  const showDropdownMenu = () => {
    setNotificationsOpen(false);
    setSidebar(false);
    setOpenDropdownMenu(!openDropdownMenu);
  };

  const changeLanguage = (newLang: string) => {
    if (lang === newLang) return;
    localStorageService.setData("lang", newLang);
    setLang(newLang);
    history.replace("/temp");
    setTimeout(() => {
      history.replace(routeName);
    });
    getHelpData();
  };

  const getHelpData = async () => {
    const helpData = await dashboardService.getHelpData();
    await setHelpData(helpData);
    setHelpContent(helpData.Dashboard);
  };

  const onScroll = async () => {
    if (notificationsRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = notificationsRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        const loadedNotifications = await notificationsService.getNotifications(
          page + 1,
          15
        );
        const mappedLoaddedNotifications = mapperService.mapNotifications(
          loadedNotifications
        );
        const allNotifications = [
          ...notifications,
          ...mappedLoaddedNotifications,
        ];

        setNotifications(allNotifications);
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const openNotifications = async () => {
    if (sidebar) setSidebar(false);
    setOpenDropdownMenu(false);
    setNotificationsOpen(!notificationsOpen);
    if (unseenNotifications.length > 0) {
      await notificationsService.makeNotificationsSeen();
      setUnseenNotifications(emptyArr);
      if (userTenants.length > 1 && user.role === "editor") {
        const userTenantsCopy = [...userTenants];
        userTenantsCopy.forEach((item: any) => {
          if (item.id === localStorageService.getData("tenantId")) {
            item.notificationsNumber = 0;
          }
        });
        setUserTenants(userTenantsCopy);
      }
    }
  };

  const navigate = (type: "link" | "component", path: string) => {
    setOpenDropdownMenu(false);
    if (type === "link") {
      window.open(path, "_blank");
    } else {
      history.push(path);
    }
  };

  const logout = () => {
    setShowLogoutModal(true);
  };

  const handleLogout = (value: any) => {
    setShowLogoutModal(false);
    if (value) {
      const token = localStorageService.getData("token");
      userService
        .logout({ refresh_token: token.refresh_token })
        .then((res: any) => {
          if (res.message === "Success") {
            const lang = localStorageService.getData("lang");
            localStorageService.clearAll();
            localStorageService.setData("lang", lang);
            const favicon: any = getFaviconEl();
            favicon.href = "/icons/logos/compliance2b.svg";
            history.push("/login");
            setUserTenants([]);
          }
        });
    }
  };

  // scroll to top on history change
  useEffect(() => {
    const scrollable: any = document.querySelector(".content");
    const unlisten = history.listen(() => {
      scrollable.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="main__header">
      <Box className="header__content">
        <Box className="title">
          <Typography variant="subtitle2">{pageTitle}</Typography>
        </Box>
        <Box className="actions">
          {user.type && user.type === "user" && (
            <Box onClick={openNotifications} className="item">
              <img
                src={icons.notifications}
                alt="Notifications"
                className="notifications-icon"
              />
              {unseenNotifications.length > 0 && (
                <Box className="rounded-notifications-number">
                  {unseenNotifications.length}
                </Box>
              )}
            </Box>
          )}
          <Box onClick={showSidebar} className="item">
            <img src={icons.help} alt="help" />
          </Box>
          <Box
            onClick={navigate.bind(null, "component", "/dashboard")}
            className="item"
          >
            <img src={icons.dashboard} alt="dashboard" />
          </Box>
          <div
            className="item"
            onClick={showDropdownMenu}
            ref={dropDownMenuRefs[0]}
          >
            <Box className="user__profile__pic" style={{ borderColor: "#000" }}>
              {user.profilePicURL && !userImageError ? (
                <img
                  src={user.profilePicURL || icons.PLACEHOLDER.user}
                  title={user.firstName + " " + user.lastName}
                  alt=""
                  onError={setUserImageError.bind(null, true)}
                />
              ) : (
                <span className="text-uppercase">
                  {user.firstName ? user.firstName[0] + user.lastName[0] : ""}
                </span>
              )}
            </Box>
          </div>
        </Box>
      </Box>

      {openDropdownMenu ? (
        <div className="dropdown-wrapper" ref={dropDownMenuRefs[1]}>
          <Box className="user__info">
            <Box className="user__profile__pic">
              {user.profilePicURL && !userImageError ? (
                <img
                  src={user.profilePicURL || icons.PLACEHOLDER.user}
                  title={user.firstName + " " + user.lastName}
                  alt=""
                  onError={setUserImageError.bind(null, true)}
                />
              ) : (
                <span className="text-uppercase">
                  {user.firstName ? user.firstName[0] + user.lastName[0] : ""}
                </span>
              )}
            </Box>
            <Box className="user__name" display="gird">
              <Typography variant="body1">
                {`${user.title ? user.title : ""} ${user?.firstName} ${
                  user?.lastName
                }`}
              </Typography>
              <Typography variant="body2" style={{ color: "#78829d" }}>
                {user.email}
              </Typography>
            </Box>
            <Box className="user__role">
              {user.role ? t(user.role) : t("USER")}
            </Box>
          </Box>
          <hr />
          <Box className="menu__items">
            <Box
              className="menu__item"
              onClick={navigate.bind(null, "component", "/settings/profile")}
            >
              <img src={icons.settings} alt="" />
              <Typography variant="body1">{t("MY_ACCOUNT")}</Typography>
            </Box>
            <Box
              className="menu__item"
              onClick={navigate.bind(
                null,
                "link",
                "https://ai-community.paiper.one"
              )}
            >
              <img src={icons.community} alt="" />
              <Typography variant="body1">{t("AI_COMMUNITY")}</Typography>
            </Box>
            <Box
              className="menu__item"
              onClick={navigate.bind(null, "component", "/users")}
            >
              <img src={icons.userManagement} alt="" />
              <Typography variant="body1">{t("USER_MANAGEMENT")}</Typography>
            </Box>
            <Box
              className="menu__item"
              onClick={changeLanguage.bind(null, lang === "de" ? "en" : "de")}
            >
              <img src={icons.language} alt="" />
              <Typography variant="body1">{t("LANGUAGE")}</Typography>
              <Box className="lang__badge">
                {lang === "de" ? "English" : "Dutch"}
                <img src={lang === "de" ? icons.en : icons.de} alt="" />
              </Box>
            </Box>
          </Box>
          <hr />
          <Box className="logout">
            <Button
              className="btn-secondary-theme mb-0 w-100"
              variant="outlined"
              color="primary"
              size="small"
              onClick={logout}
            >
              {t("LOGOUT")}
            </Button>
          </Box>
        </div>
      ) : null}

      {notificationsOpen ? (
        <div
          className={
            notificationsOpen
              ? "notifications-wrapper active"
              : "notifications-wrapper"
          }
          onScroll={() => onScroll()}
          ref={notificationsRef}
        >
          <Box className="notifications-title">{t("NOTIFICATIONS")}</Box>
          {notifications.map((item: any, index: number) => {
            return <NotificationComponent key={index} notification={item} />;
          })}
          {notifications.length === 0 && (
            <p className="no-data">{t("NO_NOTIFICATIONS_YET")}</p>
          )}
        </div>
      ) : null}

      {sidebar ? (
        <Drawer
          anchor={"right"}
          open={sidebar}
          onClose={() => setSidebar(!sidebar)}
        >
          <aside className={sidebar ? "help-aside active" : "help-aside"}>
            <Box className="mx-4 mt-4">
              <Box className="upper-part">
                <p>Hilfe</p>
                <img
                  src={icons.close}
                  onClick={() => setSidebar(false)}
                  className="close-icon"
                  alt="close-help"
                />
              </Box>
              <Box className="pb-2">
                <p dangerouslySetInnerHTML={{ __html: helpContent }} />
              </Box>
            </Box>
          </aside>
        </Drawer>
      ) : null}

      {showLogoutModal && (
        <ConfirmDialog
          show={showLogoutModal}
          onClose={handleLogout}
          dialogTitle={t("confirm logout")}
          dialogBody={t("are you sure you want to logout")}
          confirmBtnText={t("logout")}
          cancelBtn={true}
        />
      )}
    </header>
  );
}
