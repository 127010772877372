import React from "react";
import "./tasks-cards.component.scoped.scss";
import { Box } from "@material-ui/core";
import { TasksCardProps } from "../../types/tasks-card-props";

const TasksCard = (props: TasksCardProps) => {
  const { title, total, icon } = props;
  return (
    <Box className="tasks-card global__box__content global__box__content_bg">
      <Box className="d-grid align-items-center p-relative">
        <Box className="card-icon">
          <img src={icon} alt="" />
        </Box>
        <Box className="card-details">
          <Box className="card-total mb-2">{total}</Box>
          <Box className="card-title">{title}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TasksCard;
