import React from "react";
import "../auth.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";
import { history } from "react-router-guard";

const ErrorAuthComponent = () => {
  const [t] = useTranslation();

  return (
    <Box className="form-container text-center">
      <img src={icons.PAGES.AUTH.ERROR} alt="Error page" className="mb-4" />
      <Box className="mb-3 text-center">
        <Typography variant="h3">{t("WE_HAVE_LOST_THIS_PAGE")}</Typography>
        <Typography variant="body1" className="mt-2">
          {t("THE_REQUESTED_PAGE_IS_MISSING_CHECK_THE_URL_OR")}{" "}
          <a href="/login">{t("RETURN_HOME")}</a>
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className="mt-5"
        onClick={() => history.push("/login")}
      >
        {t("SIGN_IN")}
      </Button>
    </Box>
  );
};

export default ErrorAuthComponent;
