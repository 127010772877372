import React from "react";
import "./tasks-content.component.scoped.scss";
import TodoWrapperComponent from "../tasks-wrapper/tasks-wrapper.component";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "../../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";
import PagesHeadComponent from "../../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../../statics/icons";

export default function TasksContent() {
  const [t] = useTranslation();

  return (
    <Box className="todo-content">
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_TASKS_TITLE")}
        subTitle={t("PAGES_HEAD.AI_TASKS_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_TASKS}
      />
      <ContentLayout>
        <Box className="todo-wrapper theme-container">
          <TodoWrapperComponent />
        </Box>
      </ContentLayout>
    </Box>
  );
}
