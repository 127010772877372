import React from "react";
import "./pages-head.component.scoped.scss";

import { Box, Typography } from "@material-ui/core";
import { ContentLayout } from "../../layouts/content/content.layout";

const PagesHeadComponent: React.FunctionComponent<{
  title: string;
  subTitle: string;
  logo?: string;
}> = ({ title, subTitle, logo }) => {
  return (
    <Box className="py-4">
      <ContentLayout>
        <Box className="theme-container d-flex justify-content-between align-items-center mb-2">
          <Box className="title">
            <Typography variant="h1">{title}</Typography>
            <Typography variant="subtitle2">{subTitle}</Typography>
          </Box>
          {logo && <img src={logo} alt={title} title={title} width={200} />}
        </Box>
      </ContentLayout>
    </Box>
  );
};

export default PagesHeadComponent;
