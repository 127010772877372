import React, { useEffect, useContext, useState } from "react";
import "./landscape-content.component.scoped.scss";
import LandscapedFirstBlock from "../landscape-first-block/landscape-first-block.component";
import LandscapedSecondBlock from "../landscape-second-block/landscape-second-block.component";
import LandscapeThirdBlock from "../landscape-third-block/landscape-third-block.component";
import LandscapeFourthBlock from "../landscape-fourth-block/landscape-fourth-block.component";
import { GlobalContext } from "../../../../store";
import { Box } from "@material-ui/core";
import PagesHeadComponent from "../../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../../statics/icons";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "../../../../layouts/content/content.layout";
import { DashboardService } from "../../../../services/dashboard/dashboard.service";
import { MapperService } from "../../../../services/mapper/mapper.service";
import moment from "moment";
import { LandScapeCountCards } from "../../../../statics/countCards";
import SupportBlockComponent from "../../../../components/support/support.component";

export default function LandscapedContent() {
  const [t] = useTranslation();
  const dashboardService = new DashboardService();
  const mapperService = new MapperService();
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const [counts, setCounts] = useState<any>([]);
  const [highRiskCases, setHighRiskCases] = useState<any>({});
  const [latestCases, setLatestCases] = useState<any>({});
  const [openTasks, setOpenTasks] = useState<any>({});
  const [categoriesChart, setCategoriesChart] = useState();
  const [statusChart, setStatusChart] = useState();

  const fetchData = async () => {
    const landscapeData = await dashboardService.getLandscapeData();
    const mappedCounts = mapperService.mapCounts(
      landscapeData.counts,
      LandScapeCountCards
    );
    const mappedLatestCases = mapperService.mapTableCards(
      landscapeData.newestCases,
      "LATEST_CASES"
    );
    const mappedHighRiskCases = mapperService.mapTableCards(
      landscapeData.highRiskCases,
      "HIGH_RISK_CASES"
    );
    const openTodoList = mapperService.mapTableCards(
      landscapeData.openTodoList,
      "OPEN_TASKS"
    );
    setOpenTasks(openTodoList);
    setCounts(mappedCounts);
    setHighRiskCases(mappedHighRiskCases);
    setLatestCases(mappedLatestCases);
  };

  const fetchCharts = async () => {
    const durationDate = {
      from: moment().subtract(12, "months").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    const categoriesChartData = await dashboardService.getCategoriesChartData(
      durationDate
    );
    setCategoriesChart(categoriesChartData);

    const statusChartData = await dashboardService.getStatusChartData(
      durationDate
    );
    setStatusChart(statusChartData);
  };

  useEffect(() => {
    fetchData();
    fetchCharts();
    setHelpContent(helpData.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_RISK_LANDSCAPE_TITLE")}
        subTitle={t("PAGES_HEAD.AI_RISK_LANDSCAPE_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_RISK_LANDSCAPE}
      />
      <ContentLayout>
        <Box className="dashboard">
          <Box className="theme-container dashboard-content">
            <LandscapedFirstBlock counts={counts} />
            <LandscapedSecondBlock
              categoriesChart={categoriesChart}
              highRiskCases={highRiskCases}
            />
            <LandscapeThirdBlock
              statusChart={statusChart}
              latestCases={latestCases}
            />
            <LandscapeFourthBlock openTasks={openTasks} />
            <SupportBlockComponent />
          </Box>
        </Box>
      </ContentLayout>
    </Box>
  );
}
