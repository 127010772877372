import React, { useState } from "react";
import "../auth.component.scoped.scss";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { UserService } from "../../../../services/user/user.service";
import { emailRegex } from "../../../../statics/validators";
import { showToastMsg } from "../../../../helpers";
import CheckEmailComponent from "../check-email/check-email.component";

const ForgotPasswordContentComponent = () => {
  const [t] = useTranslation();
  const userService: UserService = new UserService();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event: any) => {
    const value = event.target.value;
    const valid = emailRegex.test(value);
    if (valid) {
      setEmail(value);
      setEmailError("");
    } else {
      setEmailError("invalid-email");
    }
  };

  const submit = async () => {
    setLoading(true);
    const res = await userService.sendUserForgottenPasswordEmail({
      link: `${window.location.origin}/reset-password`,
      email,
    });

    if (res.message === "success") {
      showToastMsg("success", t("Email sent successfully"));
      setFormSubmitted(true);
    } else if (res.message === "user does not exist") {
      setEmailError("Email existiert nicht");
    } else {
      setEmailError(
        "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal"
      );
    }
    setLoading(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && emailError === "" && !email) {
      submit();
    }
  };

  return (
    <>
      {formSubmitted ? (
        <CheckEmailComponent email={email} />
      ) : (
        <Box className="form-container">
          <Box className="head mb-3 text-center">
            <Typography variant="h3">{t("YOUR_EMAIL")}</Typography>
            <Typography variant="body1">
              {t("ENTER_YOUR_EMAIL_TO_RESET_PASSOWED")}
            </Typography>
          </Box>
          <form
            className="form-content"
            noValidate
            autoComplete="off"
            onKeyDown={handleKeyDown}
          >
            <Box className="standard_text_input" display="grid">
              <Typography variant="subtitle2" style={{ color: "#000" }}>
                {t("EMAIL")}
              </Typography>
              <TextField
                variant="standard"
                className="w-100"
                type="text"
                autoComplete="off"
                onInput={handleEmailChange}
                placeholder={t("EMAIL")}
              />
            </Box>

            {emailError && <Box className="error-msg">{t(emailError)}</Box>}

            <Button
              variant="contained"
              className="btn-primary-theme"
              color="primary"
              onClick={submit}
              disabled={!!emailError || loading || !email}
            >
              {!loading && t("CONFIRM")}
              {loading && <CircularProgress color="primary" />}
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};

export default ForgotPasswordContentComponent;
