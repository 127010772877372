import React from "react";
import "../auth.component.scoped.scss";
import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";
import { history } from "react-router-guard";

interface CheckEmailProps {
  email: string;
}

const CheckEmailComponent = (props: CheckEmailProps) => {
  const { email } = props;

  const [t] = useTranslation();

  return (
    <Box className="form-container text-center">
      <img
        src={icons.PAGES.AUTH.CHECK_EMAIL}
        alt="Error page"
        className="mb-4"
      />
      <Box className="mb-3 text-center">
        <Typography variant="h3">{t("CHECK_YOUR_EMAIL")}</Typography>
        <Typography variant="body1" className="mt-2">
          <Trans i18nKey="CHECK_YOUR_EMAIL_DESC" values={{ email }}></Trans>
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className="mt-4"
        onClick={() => history.push("/login")}
      >
        {t("SKIP_FOR_NOW")}
      </Button>
    </Box>
  );
};

export default CheckEmailComponent;
